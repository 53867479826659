import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from "react-bootstrap/Table";
import Spinner from 'react-bootstrap/Spinner';
import {Nav} from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
import useFetch from "../../utils/UseFetch";
import {useData} from "../../context/DataContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {ButtonWithRetry} from "../../components/ButtonWithRetry";

function TestUserList () {
    const { orgRole } = useData();
    const location = useLocation();
    const navigate = useNavigate();
    const [selOrg] = useState(() => {
        if (location.state) {
            return location.state.sel_org;
        } else {
            return orgRole.organisation;
        }
    });

    const [mockUsers, setMockUsers] = useState(null);
    const {data, loading, error} = useFetch(`/dashboard/users/list-test-users?org_id=${selOrg._id}`, true);
    useEffect(() => {
        if (data) {
            setMockUsers(data.mock_users);
        }
    }, [data]);

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    if (error) {
        return <p>Error: {error.message}</p>;
    }

    const deleteMockUser = (ev, uid) => {
        ev.preventDefault();
        console.log("Delete mock user: ", uid);
    }

    const addNewMockUser = (ev) => {
        ev.preventDefault();
        const d = { sel_org: selOrg};
        navigate('/test-users/edit/', {state: d});
    };

    const fetch_otp_ep = `/dashboard/users/get-test-user-otp?org_id=${selOrg._id}&user_id=`;

    let userComps = [];
    let i=1;
    if (mockUsers) {
        mockUsers.forEach(usr => {
            console.log("User: ", usr);
            userComps.push(
                <tr key={usr._id}>
                    <td>{i}</td>
                    <td>{usr.name}</td>
                    <td>{usr.email}</td>
                    <td>{usr.mobile}</td>
                    <td>{usr.org_admin ? "Yes" : "No"}</td>
                    <td><ButtonWithRetry dataKey={"otp"} url={fetch_otp_ep + usr._id} /></td>
                    <td>
                        <Nav>
                            <Nav.Link href="#" data-id={usr._id} onClick={(ev) => deleteMockUser(ev, usr._id)}>delete</Nav.Link>
                        </Nav>
                    </td>
                </tr>
            );
            i++;
        })
    }
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="page-head">
                        <span className="lead">Organisation - {selOrg.name} ({selOrg._id})</span>
                        <Nav className="ml-auto justify-content-end">
                            <Nav.Link href="#" onClick={addNewMockUser}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;New</Nav.Link>
                        </Nav>
                    </div>

                </Col>
                <Col xs={12}>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone No</th>
                            <th>Is Org Admin</th>
                            <td>OTP</td>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {userComps}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default TestUserList;
