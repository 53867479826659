import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from "react-bootstrap/Table";
import Spinner from 'react-bootstrap/Spinner';
import {Nav} from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
import useFetch from "../../utils/UseFetch";
import {useData} from "../../context/DataContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

function ProductList () {
    const { orgRole } = useData();
    const location = useLocation();
    const navigate = useNavigate();
    const [selOrg] = useState(() => {
        if (location.state) {
            return location.state.sel_org
        } else {
            return orgRole.organisation;
        }
    })

    const [products, setProducts] = useState([]);
    const {data, loading, error} = useFetch(`/dashboard/products/list?org_id=${selOrg._id}`, true);
    useEffect(() => {
        if (data) {
            setProducts(data.products);
        }
    }, [data]);

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    if (error) {
        return <p>Error: {error.message}</p>;
    }

    const handleView = (ev, pid) => {
        ev.preventDefault();
        const d = { product: products.filter(p => p._id === pid)[0], sel_org: selOrg};
        navigate(`/products/view/${pid}`, {state: d});
    };

    const handleAddProduct = (ev) => {
        ev.preventDefault();
        const d = { sel_org: selOrg };
        navigate("/products/edit/", {state: d});
    };

    let productComps = [];
    let i=1;
    if (products) {
        products.forEach(prd => {
            productComps.push(
                <tr key={prd._id}>
                    <td>{i}</td>
                    <td>{prd.name}</td>
                    <td>{prd.code}</td>
                    <td>{prd.status}</td>
                    <td>
                        <Nav>
                            {/*<Nav.Link href="#" data-id={prd._id} onClick={handleEdit}>Edit</Nav.Link>*/}
                            <Nav.Link href="#" data-id={prd._id} onClick={(ev) => handleView(ev, prd._id)}>View</Nav.Link>
                        </Nav>
                    </td>
                </tr>
            );
            i++;
        })
    }
    return (
        <Container fluid>
            <Row>
                <Col xs={12}>
                    <div className="page-head">
                        <span className="lead">Organisation - {selOrg.name} ({selOrg._id})</span>
                        <Nav className="ml-auto justify-content-end">
                            <Nav.Link href="#" onClick={handleAddProduct}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;New</Nav.Link>
                        </Nav>
                    </div>

                </Col>
                <Col xs={12}>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Code</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {productComps}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default ProductList;
