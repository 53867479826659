import Form from "react-bootstrap/Form";
import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

const BusinessDetails = function ({dataKey, business_details, handleOnChange}) {
    return (
        <Col xs={12} className="card card-section">
            <span className="sub-heading">Business Details</span>
                <Form.Group className="mb-3" controlId="business_type">
                    <Form.Label>Business Type</Form.Label>
                    <Form.Control as="textarea" name="business_type" rows={1} value={business_details.business_type} onChange={(ev) => handleOnChange(ev, `${dataKey}.business_type`)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="product_or_services">
                    <Form.Label>Product or Services</Form.Label>
                    <Form.Control as="textarea" name="product_or_services" rows={1}
                                  value={business_details.product_or_services} onChange={(ev) => handleOnChange(ev, `${dataKey}.product_or_services`)}/>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 border-btm" controlId="business_start_year">
                    <Form.Label column sm={6}>Business Start Year</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="text" placeholder="" name="business_start_year"
                                      value={business_details.business_start_year}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.business_start_year`)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 border-btm" controlId="business_place_ownership">
                    <Form.Label column sm={6}>Business Place Ownership</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="text" placeholder="" name="business_place_ownership"
                                      value={business_details.business_place_ownership}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.business_place_ownership`)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 border-btm" controlId="number_of_owners">
                    <Form.Label column sm={6}>Number of owners</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="text" placeholder="" name="number_of_owners"
                                      value={business_details.number_of_owners}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.number_of_owners`)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 border-btm" controlId="business_start_year">
                    <Form.Label column sm={6}>Business Start Year</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="text" placeholder="" name="business_start_year"
                                      value={business_details.business_start_year}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.business_start_year`)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 border-btm" controlId="employee_count">
                    <Form.Label column sm={6}>Employee Count</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="text" placeholder="" name="employee_count"
                                      value={business_details.employee_count}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.employee_count`)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 border-btm" controlId="customers_daily">
                    <Form.Label column sm={6}>Customers Daily</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="text" placeholder="" name="customers_daily"
                                      value={business_details.customers_daily}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.customers_daily`)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 border-btm" controlId="business_structure">
                    <Form.Label column sm={6}>Business Structure</Form.Label>
                    <Col sm={6}>
                        <Form.Control type="text" placeholder="" name="business_structure"
                                      value={business_details.business_structure}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.business_structure`)}/>
                    </Col>
                </Form.Group>
        </Col>
    )
}

function FamilyDetails({dataKey, family_details, handleOnChange, handleListChange, handleRemoveFromList, handleAddToList}) {

    return (
        <Col xs={12} className="card card-section">
            <Row className="row-pad">
                <Col xs={12}><span className="sub-heading">Family Members</span></Col>
                {family_details.members && family_details.members.map((member, index ) => (
                    <Col xs={6} className="member-section">
                        <div className="col-12 member-head">
                            <a href="#"
                               onClick={(ev) => handleRemoveFromList(ev, `${dataKey}.members`, index)}
                               className="btn btn-danger"><FontAwesomeIcon icon={faMinus}/>&nbsp;</a>
                        </div>
                        <Form.Group as={Row} className="mb-3" controlId={`name_${index}`}>
                            <Form.Label className="lead" column sm={6}>Name</Form.Label>
                            <Col sm={6}>
                                <Form.Control type="text" placeholder="" name={`name_${index}`}
                                              value={member.name}
                                              onChange={(ev) => handleListChange(ev, `${dataKey}.members`, 'name', index)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`relation_${index}`}>
                            <Form.Label className="lead" column sm={6}>Relation</Form.Label>
                            <Col sm={6}>
                                <Form.Control type="text" placeholder="" name={`relation_${index}`}
                                              value={member.relation}
                                              onChange={(ev) => handleListChange(ev, `${dataKey}.members`, 'relation', index)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`is_student_${index}`}>
                            <Form.Label className="lead" column sm={6}>Is Student?</Form.Label>
                            <Col sm={6}>
                                <Form.Check type="checkbox" name={`is_student_${index}`}
                                            checked={member.is_student}
                                            onChange={(ev) => handleListChange(ev, `${dataKey}.members`, 'is_student', index)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`occupation_${index}`}>
                            <Form.Label className="lead" column sm={6}>Occupation</Form.Label>
                            <Col sm={6}>
                                <Form.Control type="text" placeholder="" name={`occupation_${index}`}
                                              value={member.occupation}
                                              onChange={(ev) => handleListChange(ev, `${dataKey}.members`, 'occupation', index)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId={`age_${index}`}>
                            <Form.Label className="lead" column sm={6}>Age</Form.Label>
                            <Col sm={6}>
                                <Form.Control type="text" placeholder="" name={`age_${index}`}
                                              value={member.age ? member.age : ""}
                                              onChange={(ev) => handleListChange(ev, `${dataKey}.members`, 'age', index)}/>
                            </Col>
                        </Form.Group>
                    </Col>))
                }
                <Col xs={6} className="member-section">
                    <a href="#"
                       onClick={(ev) => handleAddToList(ev, `${dataKey}.members`, "family_members")}
                       className="btn btn-success"><FontAwesomeIcon icon={faPlus}/>&nbsp;</a>
                </Col>
            </Row>
        </Col>
    )
}

function CharacterDetails({
                              dataKey, character_details, handleOnChange, handleListChange,
                              handleAddToList, handleRemoveFromList
                          }) {
    console.log("CharacterDetails: ", character_details.new_to_credit);
    return (
        <Col xs={12}>
            <span className="main-heading">Character Details</span>
            <Row>
                <Col xs={12} className="card card-section">
                    <Form.Group className="mb-3" controlId="new_to_credit">
                        <Form.Label>Is new to credit?</Form.Label>
                        <Form.Check className="checkbox-custom" type="checkbox"
                                    onChange={(ev) => handleOnChange(ev, `${dataKey}.new_to_credit`)}
                                    name="new_to_credit" checked={character_details.new_to_credit}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="business_summary">
                        <Form.Label>Business Summary</Form.Label>
                        <Form.Control as="textarea" name="business_summary" rows={12}
                                      value={character_details.business_summary}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.business_summary`)}/>
                    </Form.Group>
                    <BusinessDetails dataKey={`${dataKey}.business_details`} business_details={character_details.business_details}
                                     handleOnChange={handleOnChange}/>
                    <Form.Group className="mb-3" controlId="family_summary">
                        <Form.Label>Family Summary</Form.Label>
                        <Form.Control as="textarea" name="family_summary" rows={12}
                                      value={character_details.family_summary}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.family_summary`)}/>
                    </Form.Group>
                    <FamilyDetails family_details={character_details.family_details} dataKey={`${dataKey}.family_details`}
                                   handleOnChange={handleOnChange}
                                   handleListChange={handleListChange}
                                   handleAddToList={handleAddToList}
                                   handleRemoveFromList={handleRemoveFromList}/>
                </Col>
            </Row>

        </Col>
    )
}

export default CharacterDetails;