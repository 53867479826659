import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { isEmpty } from '../../utils/TextUtil'
import Alert from 'react-bootstrap/Alert';
import ac_logo from '../../images/ac_logo.png';
import { useNavigate } from 'react-router-dom';
import "../../css/auth.css";


const Login = () => {

    const navigate = useNavigate();
    const { user, sendOTP } = useAuth();

    useEffect(() => {
        if (user) {
            navigate("/dashboard");
        }
    }, []);

    const [formData, setFormData] = useState({
        mobile: '',
        country_code: '+91'
    });
    const [showAlert, setShowAlert] = useState(false);
    const [errors, setErrors] = useState({
        mobile: ''
    });


    const handleChange = (ev) => {
        const {name, value} = ev.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
        setShowAlert(false);
    };

    const handleLogin = (ev) => {
        ev.preventDefault();
        if (isEmpty(formData.mobile)) {
            setErrors({
                ...errors,
                mobile: 'Phone number is required'
            });
            setShowAlert(true);
        } else {
            sendOTP("mobile", formData.mobile, formData.country_code);
        }
    };

    return (
        <Container>
            <Row>
                <Col>
                    {showAlert && (
                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                            {errors.mobile}
                        </Alert>
                    )}
                </Col>
            </Row>
            <Row className="login-row">
                <Col xs={7} className="right-border">
                    <Card className="card-custom">
                        <Card.Img variant="top" src={ac_logo}/>
                    </Card>
                    <Card className="card-custom">
                        <Card.Body>
                            <Card.Title className="card-title-nw">AI that writes Credit Reports on its own !!</Card.Title>
                            <Card.Text>
                                AbleCredit is your friendly, supportive credit assistant. It generates Credit Appraisal Memos based on your policies, without any human intervention.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={5}>
                    <Form  onSubmit={handleLogin}>
                        <h2>Login</h2>
                        <Form.Group className="mb-3" controlId="mobile">
                            <Form.Label>Phone Number</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
                                <Form.Control type="text" placeholder="Enter mobile" onChange={handleChange} name="mobile"/>
                            </InputGroup>
                        </Form.Group>
                        <Button variant="primary" className="btn-lg login-btn-nw" type="submit">
                            Send OTP
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
