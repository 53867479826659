import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DefaultContent from "./DefaultContent";
import { useData } from "../../context/DataContext";
import Container from 'react-bootstrap/Container';
import Sidebar from "../../components/Sidebar";
import DashboardNav from "./DashboardNav";
import "../../css/dashboard.css"

const Dashboard = () => {

    const { orgRole, fetchHome } = useData();

    useEffect(() => {
        fetchHome();
    }, []);

    if (!orgRole) {
        return <p>Unable to fetch dashboard data</p>
    }

    const component = "dashboard";
    const action = "view";

    let currentComponent;
    let currentComponents = orgRole.roles.filter(r => r.name === component);
    if (currentComponents && currentComponents.length > 0){
        currentComponent = currentComponents[0];
    }

    return (
        <Container fluid>
            <Row>
                <Col xs={12}>
                    <DashboardNav data={orgRole}/>
                </Col>
            </Row>
            <Row className="full-height">
                <Col xs={2}>
                    <Sidebar data={orgRole}/>
                </Col>
                <Col xs={10}>
                    <Container sx={{ margin: 2 }}>
                        <DefaultContent data={orgRole}/>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default Dashboard;
