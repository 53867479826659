// src/components/BranchEdit.js

import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Modal, Nav} from "react-bootstrap";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {isEmpty} from "../../utils/TextUtil";
import {jsonReq} from "../../utils/HttpUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartSimple, faFile} from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-bootstrap/Spinner";
import Accordion from 'react-bootstrap/Accordion';
import PDAudioComponent from "../../components/cams/PDAudioComponent";
import LifestyleComponent from "../../components/cams/LifestyleComponent";
import BusinessImages from "../../components/cams/BusinessImages";
import GeoLocation from "../../components/cams/GeoLocation";
import BorrowerDetails from "../../components/cams/BorrowerDetails";
import LoanDetails from "../../components/cams/LoanDetails";
import CollateralImages from "../../components/cams/CollateralImages";
import CoBorrowerDetails from "../../components/cams/CoBorrowerDetails";
import {useAuth} from "../../context/AuthContext";

function ConfirmationModal({confirmation, application_id, closeConfirmation, publishCam, publishing, publishSuccess}) {

    return (
        <Modal show={confirmation} onHide={closeConfirmation}>
            <Modal.Header closeButton>
                <Modal.Title>Publish Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you want to publish the report?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeConfirmation}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={publishing ? null : (ev) => publishCam(ev)}>
                    {publishing ? 'Publishing...': 'Publish Report'}
                </Button>
            </Modal.Footer>
        </Modal>
    )

}

function CamEdit() {
    const navigate = useNavigate();
    const {application_id} = useParams()
    const { user } = useAuth();
    const [camTemplateId, setCamTemplateId] = useState("")
    const [loading, setLoading] = useState(true);
    const [application, setApplication] = useState(null);
    const [fetch, setFetch] = useState(true)
    const [error, setError] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [submitError, setSubmitError] = useState("");
    const [isSaving, setIsSaving] = useState(false)
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [publishSuccess, setPublishSuccess] = useState(false);
    const [publishing, setPublishing] = useState(false);
    const [previewing, setPreviewing] = useState(false);
    const [previewAlert, setPreviewAlert] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [reCalculate, setReCalculate] = useState(false);
    const [camForm, setCamForm] = useState(() => {
        if (application) {
            return application.cams[0].output; // Assuming only one cam for now
        }
        return null;
    })
    const calculableFields = [
        'monthly_gross_earnings',
        'net_operating_income',
        'net_monthly_income',
        'current_dscr',
        'new_emi',
        'dscr_loan_amount',
        'retained_income',
        'foir_limit',
        'foir_loan_amount'
    ]

    function convertToNumber(str) {
        const num = Number(str); // Convert the string to a number
        return isNaN(num) ? -1 : num; // Check if the result is NaN, return -1 if so
    }

    useEffect(() => {
        if (reCalculate) {
            // Calculate values
            calculableFields.forEach(cf => {
                switch (cf) {
                    case 'monthly_gross_earnings': {
                        camForm.pd_audio.capabilities.income_calculations.data[2][1] = convertToNumber(camForm.pd_audio.capabilities.income_calculations.data[0][1]) - convertToNumber(camForm.pd_audio.capabilities.income_calculations.data[1][1])
                        break;
                    }
                    case 'net_operating_income': {
                        camForm.pd_audio.capabilities.income_calculations.data[4][1] = convertToNumber(camForm.pd_audio.capabilities.income_calculations.data[2][1]) - convertToNumber(camForm.pd_audio.capabilities.income_calculations.data[3][1])
                        break;
                    }
                    case 'net_monthly_income': {
                        const nmo = convertToNumber(camForm.pd_audio.capabilities.income_calculations.data[4][1]) + convertToNumber(camForm.pd_audio.capabilities.income_calculations.data[5][1]) - convertToNumber(camForm.pd_audio.capabilities.income_calculations.data[6][1])
                        camForm.pd_audio.capabilities.income_calculations.data[7][1] = nmo;
                        camForm.pd_audio.capabilities.dscr.data[0][1] = nmo;
                        camForm.pd_audio.capabilities.foir.data[0][1] = nmo;
                        break;
                    }
                    case 'current_dscr': {
                        camForm.pd_audio.capabilities.dscr.data[4][1] = Math.floor(convertToNumber(camForm.pd_audio.capabilities.dscr.data[0][1])/(convertToNumber(camForm.pd_audio.capabilities.dscr.data[3][1]) !== 0 ? convertToNumber(camForm.pd_audio.capabilities.dscr.data[3][1]) : 1))
                        break
                    }
                    case 'new_emi': {
                        camForm.pd_audio.capabilities.dscr.data[6][1] = Math.floor(convertToNumber(camForm.pd_audio.capabilities.dscr.data[0][1])/convertToNumber(camForm.pd_audio.capabilities.dscr.data[5][1])) - camForm.pd_audio.capabilities.dscr.data[3][1]
                        break;
                    }
                    case 'dscr_loan_amount': {
                        camForm.pd_audio.capabilities.dscr.data[7][1] = Math.floor((convertToNumber(camForm.pd_audio.capabilities.dscr.data[6][1])/convertToNumber(camForm.pd_audio.capabilities.dscr.data[2][1]))*100000)
                        break;
                    }
                    case 'retained_income': {
                        camForm.pd_audio.capabilities.foir.data[2][1] = convertToNumber(camForm.pd_audio.capabilities.foir.data[0][1]) - convertToNumber(camForm.pd_audio.capabilities.foir.data[1][1])
                        break;
                    }
                    case 'foir_limit': {
                        camForm.pd_audio.capabilities.foir.data[5][1] = Math.floor(0.55 * convertToNumber(camForm.pd_audio.capabilities.foir.data[2][1]))
                        break;
                    }
                    case 'foir_loan_amount': {
                        camForm.pd_audio.capabilities.foir.data[6][1] = Math.floor((convertToNumber(camForm.pd_audio.capabilities.foir.data[5][1]) / convertToNumber(camForm.pd_audio.capabilities.foir.data[4][1]))*100000)
                        break;
                    }
                    default: {
                        // Do Nothing
                        break;
                    }
                }
            })
            setReCalculate(false)
        }
    }, [reCalculate]);


    useEffect(() => {
        const fetchApplication = async () => {
            try {
                const res = await jsonReq().get("/dashboard/applications/get-cam?application_id="+application_id);
                const appl = res.data.data.application;
                setApplication(appl);
                setCamTemplateId(appl.cams[0].template_id)
                setCamForm(appl.cams[0].output)
            }catch (e) {
                console.log("Error fetching organisation list: ", e);
                setError(e);
            }finally {
                setLoading(false);
                setFetch(false)
            }
        }
        fetchApplication()
    }, [fetch])

    const getSectionComponent = (k, sec) => {
        switch (k) {
            case 'pd_audio':
                return <PDAudioComponent dataKey={k} pd_audio={sec}
                                         handleOnChange={handleOnChange} handleListChange={handleListChange}
                                         handleListOfListChange={handleListOfListChange}
                                         handleAddToList={handleAddToList} handleRemoveFromList={handleRemoveFromList}/>
            case 'lifestyle_images':
                return <LifestyleComponent dataKey={k} lifestyle_images={sec}
                                           handleOnChange={handleOnChange} handleListChange={handleListChange}
                                           handleListOfListChange={handleListOfListChange}
                                           handleAddToList={handleAddToList} handleRemoveFromList={handleRemoveFromList}/>
            case 'business_images':
                return <BusinessImages dataKey={k} business_images={sec}
                                       handleOnChange={handleOnChange} handleListChange={handleListChange}
                                       handleListOfListChange={handleListOfListChange}
                                       handleAddToList={handleAddToList} handleRemoveFromList={handleRemoveFromList}/>
            case 'geolocation':
                return <GeoLocation dataKey={k} geolocation={sec}
                                    handleOnChange={handleOnChange} />
            case 'borrower_details':
                return <BorrowerDetails dataKey={k} borrower_details={sec} />
            case 'loan_details':
                return <LoanDetails dataKey={k} loan_details={sec}/>
            case 'collateral_images':
                return <CollateralImages dataKey={k} collateratl_images={sec}/>
            case 'co_borrower_details':
                return <CoBorrowerDetails dataKey={k} co_borrower_details={sec}/>
            default:
                return <p>This is a {k} component</p>
        }
    }

    // Calculations
    // Income Calculation - Monthly gross turnover
    const handleCalculableFieldChange = (ev) => {

    }

    const updateForm = (form, key, val) => {
        const ss = key.split('.')
        if (ss.length === 1) {
            // single key
            form = {
                ...form,
                [key]: val
            }
        } else {
            form = {
                ...form,
                [ss[0]]: updateForm(form[ss[0]], ss.slice(1).join("."), val)
            }
        }
        return form;
    }

    const updateList = (form, key, name, index, val) => {
        const ss = key.split('.')
        if (ss.length === 1) {
            const updatedMembers = [...form[key]]
            updatedMembers[index] = {...updatedMembers[index], [name]: val}
            form = {
                ...form,
                [key]: updatedMembers
            }
        } else {
            form = {
                ...form,
                [ss[0]]: updateList(form[ss[0]], ss.slice(1).join("."), name, index,val)
            }
        }
        return form;
    }

    const updateListOfList = (form, key, ix1, ix2, val) => {
        const ss = key.split('.')
        if (ss.length === 1) {
            const outerList = [...form[key]]
            outerList[ix1][ix2] = val
            form = {
                ...form,
                [key]: outerList
            }
        } else {
            form = {
                ...form,
                [ss[0]]: updateListOfList(form[ss[0]], ss.slice(1).join("."), ix1, ix2,val)
            }
        }
        return form;
    }


    const handleOnChange = (ev, key) => {
        // ev.preventDefault();
        const {type, value, checked} = ev.target;
        let dataVal = value;
        if (type ==='checkbox') {
            dataVal = checked;
        }
        let finalForm = updateForm(camForm, key, dataVal)
        setCamForm(pv => finalForm)
    }

    const handleListChange = (ev, key, name, index) => {
        ev.preventDefault();
        const {type, value, checked} = ev.target;
        let dataVal = value;
        if (type ==='checkbox') {
            dataVal = checked;
        }
        setCamForm(pv => updateList(camForm, key, name, index, dataVal))
    }

    const handleListOfListChange = (ev, key, ix1, ix2, recal=false) => {
        ev.preventDefault();
        const {type, value, checked} = ev.target;
        let dataVal = value;
        if (type ==='checkbox') {
            dataVal = checked;
        }
        setCamForm(pv => updateListOfList(camForm, key, ix1, ix2, dataVal))
        if (recal) {
            setReCalculate(true)
        }
    }

    const getListValue = (key) => {
        switch (key) {
            case 'obligations': {
                return ['', '', '', '']
            }
            case 'expenses': {
                return ['', '']
            }
            case 'family_members': {
                return {
                    name: "",
                    relation: "",
                    is_student: false,
                    occupation: "",
                    age: ""
                }
            }
            case 'inventory': {
                return ['', '', '', '']
            }
            case 'income_details':
                return {
                    "name": "",
                    "total_gross_revenue": 0,
                    "frequency": "",
                    "selling_price_per_unit": 0,
                    "cost_per_unit": 0,
                    "units_sold": 0
                }
            default: {
                return ['']
            }
        }
    }

    const addToList = (obj, key, data_key) => {
        const ss = key.split('.')
        if (ss.length === 1) {
            let lst = [...obj[key]]
            let t = lst[0];
            let temp;
            if (!t) {
                temp = getListValue(data_key);
            } else {
                temp = JSON.parse(JSON.stringify(lst[0]));
            }
            Object.keys(temp).forEach((k, i) => temp[k] = "");
            lst.push(temp)
            obj = {
                ...obj,
                [key]: lst
            }
        } else {
            obj = {
                ...obj,
                [ss[0]]: addToList(obj[ss[0]], ss.slice(1).join("."), data_key)
            }
        }
        return obj;
    }

    const handleAddToList = (ev, key, data_key) => {
        ev.preventDefault();
        setCamForm(pv => addToList(camForm, key, data_key))
    }

    const removeFromList = (obj, key, idx) => {
        const ss = key.split('.')
        if (ss.length === 1) {
            let lst = [...obj[key]]
            lst.splice(idx, 1)
            obj = {
                ...obj,
                [key]: lst
            }
        } else {
            obj = {
                ...obj,
                [ss[0]]: removeFromList(obj[ss[0]], ss.slice(1).join("."), idx)
            }
        }
        return obj;
    }

    const handleRemoveFromList = (ev, key, idx) => {
        ev.preventDefault();
        setCamForm(pv => removeFromList(camForm, key, idx))
    }

    const saveCam = (ev) => {
        ev.preventDefault();
        setIsSaving(true)
        jsonReq().post('/dashboard/applications/save-cam',
            {cam_template_id: camTemplateId, application_id: application_id, cam_data: camForm})
            .then(res => {
                console.log("CAM Saved: ");
                let app = res.data.data.application
                setApplication(app);
                setCamForm(app.cams[0].output)
                setCamTemplateId(app.cams[0].template_id)
                setSaveSuccess(true)
        }).catch(er => {
            setShowAlert(true);
            setSubmitError(er.message);
        }).finally(() => {
            setIsSaving(false)
        })
    }

    const previewCam = (ev) => {
        ev.preventDefault();
        setPreviewing(true);
        jsonReq().get(`/dashboard/applications/preview?application_id=${application_id}`)
            .then(res => {
                const html = res.data.data.html;
                const win = window.open('', '_blank')
                win.document.write(html);
            }).catch(err => {
                setPreviewAlert(true);
                setSubmitError(err.message)
            }).finally(() => {
                setPreviewing(false)
        })
    }

    const showConfirmation = (ev) => {
        ev.preventDefault();
        setConfirmation(true)
    }

    const closeConfirmation = (ev) => {
        if (ev) {
            // in case of hide no ev is present
            ev.preventDefault();
        }
        setConfirmation(false)
    }

    const publishCam = (ev) => {
        ev.preventDefault();
        setPublishing(true)
        jsonReq().post('/dashboard/applications/publish-cam', {application_id: application_id})
            .then(res => {
                console.log('cam published..')
                setPublishSuccess(true)
                setTimeout(() => {
                    navigate(`/applications/view/${application._id}`)
                }, 2000);
            }).catch(err => {
                setShowAlert(true);
                setSubmitError(err.message);
            }).finally(() => {
                setPublishing(false)
                setConfirmation(false);
        })
    }

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }

    if (error) {
        return (<p>Error: {error.message}</p>);
    }

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="page-head">
                        <span className="lead">{application && application.organisation.name} ({application && application.organisation._id}) (Able ID - {application_id})</span>
                        <Nav className="ml-auto justify-content-end">
                            <Nav.Link href={`/applications/view/${application_id}`}><FontAwesomeIcon
                                icon={faFile}/>&nbsp;&nbsp;View Application</Nav.Link>
                            <Nav.Link href="#" onClick={previewing ? null : (ev) => previewCam(ev)} className="btn btn-info"><FontAwesomeIcon
                                icon={faChartSimple}/>&nbsp;&nbsp;{previewing ? 'Opening...' : 'Preview CAM'}</Nav.Link>
                        </Nav>
                    </div>
                </Col>
                <Col xs={12}>
                    <ConfirmationModal application_id={application_id} closeConfirmation={closeConfirmation}
                                       confirmation={confirmation} publishCam={publishCam}
                                       publishing={publishing} publishSuccess={publishSuccess}
                    />
                </Col>
                <Col xs={12}>
                    {showAlert && (
                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                            There was an error <br/>
                            {submitError}
                        </Alert>
                    )}
                    {previewAlert && (
                        <Alert variant="danger" onClose={() => setPreviewAlert(false)} dismissible>
                            There was an error while previewing the CAM <br/>
                            {submitError}
                        </Alert>
                    )}
                    {publishSuccess && (
                        <Alert variant="success" onClose={() => setPublishSuccess(false)} dismissible>
                            CAM published successfully
                        </Alert>
                    )}
                </Col>
                <Col xs={12}>
                    <Form onSubmit={saveCam}>
                        <Row>
                            <Accordion defaultActiveKey="pd_audio">
                                {camForm && Object.entries(camForm).map(field => {
                                    return getSectionComponent(field[0], field[1]);
                                })}
                            </Accordion>
                        </Row>
                        <Row className="row-pad">
                            <Col xs={4}>
                                {saveSuccess && (
                                    <Alert variant="success" onClose={() => setSaveSuccess(false)} dismissible>
                                        CAM saved successfully
                                    </Alert>
                                )}
                                <Button variant="primary" type="submit" className="btn-dark btn-lg"
                                        onClick={isSaving ? null : (ev) => saveCam(ev)}>
                                    {isSaving ? 'Saving...' : 'Save Report'}
                                </Button>
                            </Col>
                            {/*<Col xs={4}>*/}
                            {/*    <a href="#" className="btn btn-lg btn-info">Preview Report</a>*/}
                            {/*</Col>*/}
                            <Col xs={4}>
                                {['shwetanka@ablecredit.com', 'sanjeev@ablecredit.com', 'karthik.s@ablecredit.com', 'shrinidhi.v@ablecredit.com'].includes(user.email) && (
                                    <a href="#" className="btn btn-lg btn-success" onClick={showConfirmation}>
                                        Publish Report
                                    </a>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default CamEdit;
