import {getTitle} from "../../utils/TextUtil";
import Accordion from "react-bootstrap/Accordion";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function BorrowerDetails({dataKey, borrower_details}) {
    return (
        <Accordion.Item eventKey={dataKey} key={dataKey}>
            <Accordion.Header>{getTitle(dataKey)}</Accordion.Header>
            <Accordion.Body>
                <Row>
                    <Col xs={12}>
                        <table className="table table-striped">
                            <tbody>
                            <tr>
                                <th>Borrower Photo</th>
                                <td>{borrower_details.borrower_photo}</td>
                            </tr>
                            <tr>
                                <th>Name</th>
                                <td>{borrower_details.name}</td>
                            </tr>
                            <tr>
                                <th>Mobile</th>
                                <td>{borrower_details.mobile}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{borrower_details.email}</td>
                            </tr>
                            <tr>
                                <th>DOB</th>
                                <td>{borrower_details.dob}</td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    )
}
export default BorrowerDetails;
