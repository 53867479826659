// src/components/OrganisationList.js

import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from "react-bootstrap/Table";
import { jsonReq } from "../../utils/HttpUtil";
import Spinner from 'react-bootstrap/Spinner';
import {Nav} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

function OrganisationList() {
    const [organisations, setOrganisations] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrgs = async () => {
            try {
                const res = await jsonReq().get("/dashboard/organisations/list");
                setOrganisations(res.data.data.organisations);
            }catch (e) {
                console.log("Error fetching organisation list: ", e);
                setError(e);
            }finally {
                setLoading(false);
            }
        }
        fetchOrgs()
    }, []);

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    if (error) {
        return (<p>Error: {error.message}</p>);
    }

    const handleApplications = (ev, org_id) => {
        ev.preventDefault();
        const data = { sel_org: organisations.filter(org => org._id === org_id)[0] };
        navigate('/applications/list', { state: data });
    };

    let orgComps = [];
    let i=1;
    organisations.forEach(org => {
        orgComps.push(
            <tr key={org._id}>
                <td>{i}</td>
                <td>{org._id}</td>
                <td>{org.name}</td>
                <td>{org.type}</td>
                <td>{org.status}</td>
                <td>
                    <Nav>
                        <Nav.Link href={`/organisations/view/${org._id}`} data-id={org._id}>View</Nav.Link>
                        <Nav.Link>|</Nav.Link>
                        <Nav.Link href={`/applications/list`} onClick={(ev) => handleApplications(ev, org._id)}>Applications({org.application_count})</Nav.Link>
                    </Nav>
                </td>
            </tr>
        );
        i++;
    });

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="page-head">
                        <span className="lead">Organisation List</span>
                        <Nav className="ml-auto justify-content-end">
                            <Nav.Link href="/organisations/edit"><FontAwesomeIcon
                                icon={faPlus}/>&nbsp;&nbsp;New</Nav.Link>
                        </Nav>
                    </div>
                </Col>
                <Col xs={12}>
                <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orgComps}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default OrganisationList;
