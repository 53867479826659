import React, {useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DashboardNav from '../dashboard/DashboardNav';
import { useData } from '../../context/DataContext'
import DefaultContent from "../dashboard/DefaultContent";
import Sidebar from "../../components/Sidebar";
import ApplicationList from "./ApplicationList";
import ApplicationView from "./ApplicationView";

const Application = (data) => {

    const { orgRole, fetchHome } = useData();
    const cData = data.data || {};
    const action = cData.cAction ? cData.cAction : "list";

    let mainComp = <DefaultContent data={orgRole}/>;
    if (action === "list") {
        mainComp = <ApplicationList/>
    } else if (action === 'view') {
        mainComp = <ApplicationView/>
    }

    useEffect(() => {
        if (!orgRole) {
            fetchHome();
        }
    }, []);

    return (
        <Container fluid>
            <Row>
                <Col xs={12}>
                    <DashboardNav data={orgRole}/>
                </Col>
            </Row>
            <Row className="full-height">
                <Col xs={2}>
                    <Sidebar data={orgRole}/>
                </Col>
                <Col xs={10}>
                    <Container sx={{ margin: 2 }}>
                        {mainComp}
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default Application;
