import {getTitle} from "../../utils/TextUtil";
import Accordion from "react-bootstrap/Accordion";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function GeoLocation({dataKey, geolocation, handleOnChange}) {
    return (
        <Accordion.Item eventKey={dataKey} key={dataKey}>
            <Accordion.Header>{getTitle(dataKey)}</Accordion.Header>
            <Accordion.Body>
                <Row>
                    <Col xs={12}>
                        <span className="main-heading">Geo</span>
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    )
}
export default GeoLocation;
