import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from "react-bootstrap/Table";
import Spinner from 'react-bootstrap/Spinner';
import {Nav} from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
import useFetch from "../../utils/UseFetch";
import {useData} from "../../context/DataContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

function UserList () {
    const { orgRole } = useData();
    const location = useLocation();
    const navigate = useNavigate();
    const [selOrg] = useState(() => {
        if (location.state) {
            return location.state.sel_org;
        } else {
            return orgRole.organisation;
        }
    });

    const [users, setUsers] = useState(null);
    const {data, loading, error} = useFetch(`/dashboard/users/list?org_id=${selOrg._id}`, true);
    useEffect(() => {
        if (data) {
            setUsers(data.users);
        }
    }, [data]);

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    if (error) {
        return <p>Error: {error.message}</p>;
    }

    const viewUser = (ev, uid) => {
        ev.preventDefault();
        const d = { sel_org: selOrg};
        navigate(`/users/view/${uid}`, {state: d});
    };

    const addNewUser = (ev) => {
        ev.preventDefault();
        const d = { sel_org: selOrg};
        navigate('/users/edit/', {state: d});
    };

    let userComps = [];
    let i=1;
    if (users) {
        users.forEach(usr => {
            userComps.push(
                <tr key={usr._id}>
                    <td>{i}</td>
                    <td>{usr.name}</td>
                    <td>{usr.email}</td>
                    <td>{usr.mobile}</td>
                    <td>{usr.org_user?.emp_id}</td>
                    <td>
                        <Nav>
                            <Nav.Link href={`/users/view/${usr._id}`} data-id={usr._id} onClick={(ev) => viewUser(ev, usr._id)}>View</Nav.Link>
                        </Nav>
                    </td>
                </tr>
            );
            i++;
        })
    }
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="page-head">
                        <span className="lead">Organisation - {selOrg.name} ({selOrg._id})</span>
                        <Nav className="ml-auto justify-content-end">
                            <Nav.Link href="#" onClick={addNewUser}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;New</Nav.Link>
                        </Nav>
                    </div>

                </Col>
                <Col xs={12}>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone No</th>
                            <th>Customer User ID</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {userComps}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default UserList;
