// src/components/OrganisationEdit.js
import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import useFetch from "../../utils/UseFetch";
import Spinner from "react-bootstrap/Spinner";
import {jsonReq} from "../../utils/HttpUtil";
import Multiselect from "multiselect-react-dropdown";

function OrganisationEdit() {
    const location = useLocation();
    const navigate = useNavigate();
    const {org_id} = useParams()
    const [organisation, setOrganisation] = useState(() => {
        if (location.state){
            return location.state.organisation;
        }
        return null;
    })
    const [forms, setForms] = useState([]);
    const [fetchForms, setFetchForms] = useState(true);
    const [fetch, setFetch] = useState(() => {
        if (organisation) {
            return false
        }
        return !!org_id;
    });
    const [edit, setEdit] = useState(() => {
        return !!org_id;
    })
    const [showAlert, setShowAlert] = useState(false);
    const [submitError, setSubmitError] = useState("");
    const {data, loading, error} = useFetch(`/dashboard/organisations/get-for-edit?org_id=${org_id}`, fetch);
    const {data: formsData, loading: formsLoading, error: formsError} = useFetch(`/dashboard/forms/default`, fetchForms);

    useEffect(() => {
        if (data) {
            setOrganisation(data.organisation);
        }
    }, [data]);

    useEffect(() => {
        if (formsData) {
            setForms(formsData.forms);
            setFetchForms(false)
        }
    }, [formsData]);

    const [orgForm, setOrgForm] = useState(() => {
        if (organisation) {
            return {
                org_id: organisation._id,
                org_name: organisation.name,
                org_type: organisation.type,
                org_logo: organisation.logo,
                form_ids: organisation.forms,
            }
        }
        return {
            org_id: "",
            org_name: "",
            org_type: "",
            org_logo: "",
            user_name: "",
            user_email: "",
            user_mobile: "",
            form_ids: [],
        }
    });

    let formOptions = []
    let selectedForms = []
    if (forms && forms.length > 0) {
        formOptions = forms.map(f => ({name: `${f.name} (${f.identifier})`, id: f._id}))
    }

    if (loading || formsLoading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    if (error || formsError) {
        return <p>Error: {error.message}</p>;
    }

    const handleChange = (ev) => {
        const {name, value} = ev.target;
        setOrgForm({
            ...orgForm,
            [name]: value
        });
    };

    const addFormToOrg = (selectedList, selectedItem) => {
        setOrgForm({
            ...orgForm,
            form_ids: selectedList.map(sl => sl.id)
        })
    }

    const removeFormToOrg = (selectedList, selectedItem) => {
        setOrgForm({
            ...orgForm,
            form_ids: selectedList.map(sl => sl.id)
        })
    }

    const handleSubmit = (ev) => {
        ev.preventDefault();
        let url = "/dashboard/organisations/create";
        if (orgForm.org_id) {
            url = "/dashboard/organisations/update"
        }
        console.log("ORG_FORM:", orgForm);
        // jsonReq().post(url, orgForm).then(res => {
        //     console.log("Save Organisation response: ", res.data);
        //     navigate("/organisations/list")
        // }).catch(e => {
        //     console.log("Error while saving the organisation: ", e);
        //     setShowAlert(true);
        //     setSubmitError(e.message);
        // });
    };

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    {showAlert && (
                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                            There was an error while saving the organisation <br/>
                            {submitError}
                        </Alert>
                    )}
                </Col>
                <Col xs={12}>
                    <div className="clearfix">
                        <div className="col-12 lead fs-4 fw-bold float-start">
                            <span>Add/Edit Organisation</span>
                        </div>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Control type="hidden" placeholder="" name="org_id" value={orgForm.org_id}/>
                        <Form.Group className="mb-3" controlId="org_name">
                            <Form.Label>Name of the Organisation</Form.Label>
                            <Form.Control type="text" placeholder="" name="org_name" value={orgForm.org_name}
                                          onChange={handleChange}/>
                            <Form.Text className="text-muted">
                                Registered name of the organisation.
                            </Form.Text>
                        </Form.Group>
                        <Form.Control type="hidden" placeholder="" name="org_logo" value={orgForm.org_logo}/>
                        <Form.Group className="mb-3" controlId="org_type">
                            <Form.Label>Organisation Type</Form.Label>
                            <Form.Select name="org_type" value={orgForm.org_type} onChange={handleChange}>
                                <option value="BANK">BANK</option>
                                <option value="NBFC">NBFC</option>
                                <option value="SME">SME</option>
                                <option value="MSME">MSME</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="form_ids">
                            <Form.Label className="lead">Forms</Form.Label>
                            <Multiselect className="white-bg" options={formOptions} selectedValues={selectedForms} displayValue="name" placeholder="Select Forms" onSelect={addFormToOrg} onRemove={removeFormToOrg}/>
                        </Form.Group>
                        {!edit && (
                            <Form.Group className="mb-3" controlId="user_name">
                                <Form.Label>Your Name</Form.Label>
                                <Form.Control type="text" placeholder="" name="user_name" value={orgForm.user_name}
                                              onChange={handleChange}/>
                            </Form.Group>
                        )}
                        {!edit && (
                            <Form.Group className="mb-3" controlId="user_email">
                                <Form.Label>Your Organisation Email Address</Form.Label>
                                <Form.Control type="email" placeholder="" name="user_email" value={orgForm.user_email}
                                              onChange={handleChange}/>
                            </Form.Group>
                        )}
                        {!edit && (
                            <Form.Group className="mb-3" controlId="user_mobile">
                                <Form.Label>Your Official Phone Number</Form.Label>
                                <Form.Control type="text" placeholder="" name="user_mobile" value={orgForm.user_mobile}
                                              onChange={handleChange}/>
                            </Form.Group>
                        )}
                        <Button variant="primary" type="submit" className="btn-lg login-btn-nw">
                            Save Organisation
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default OrganisationEdit;
