// src/components/BranchEdit.js

import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Nav} from "react-bootstrap";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {isEmpty} from "../../utils/TextUtil";
import {jsonReq} from "../../utils/HttpUtil";
import {useData} from "../../context/DataContext";
import useFetch from "../../utils/UseFetch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";
import Ajv from 'ajv'
import Spinner from "react-bootstrap/Spinner";

function CamView() {
    const location = useLocation();
    const navigate = useNavigate();

    const [selOrg, setSelOrg] = useState(() => {
        if (location.state) {
            return location.state.sel_org
        }
    })

    return (
        <Container>
            <Row>
                <p>Cam edit page</p>
            </Row>
        </Container>
    );
}

export default CamView;
