import Col from "react-bootstrap/Col";
import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

function IncomeDetails({dataKey, income_details, handleOnChange, handleListChange, handleListOfListChange,
                           handleAddToList, handleRemoveFromList}) {
    return (
        <Col xs={12}>
            <span className="sub-heading">Income Details</span>
            <Form.Group className="mb-3" controlId="business_summary">
                <Form.Label>Summary</Form.Label>
                <Form.Control as="textarea" name="business_summary" rows={12}
                              value={income_details.summary}
                              onChange={(ev) => handleOnChange(ev, `${dataKey}.summary`)}/>
            </Form.Group>
            <Row className="row-pad">
                <Col xs={12}><span className="sub-heading">Income Sources</span></Col>
                {income_details.sources && income_details.sources.map((source, index) => (
                    <Col xs={6} className="member-section" key={`inc_src_${index}`}>
                        <div className="col-12 member-head">
                            <a href="#"
                               onClick={(ev) => handleRemoveFromList(ev, `${dataKey}.sources`, index)}
                               className="btn btn-danger"><FontAwesomeIcon icon={faMinus}/>&nbsp;</a>
                        </div>
                        <Form.Group as={Row} className="mb-3 border-btm" controlId={`name_${index}`}>
                            <Form.Label column sm={4}>Name</Form.Label>
                            <Col sm={8}>
                            <Form.Control type="text" placeholder="" name={`name_${index}`}
                                              value={source.name}
                                              onChange={(ev) =>
                                                  handleListChange(ev, `${dataKey}.sources`, 'name', index)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3 border-btm" controlId={`total_gross_revenue_${index}`}>
                            <Form.Label column sm={4}>Total Gross Revenue</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text" placeholder="" name={`total_gross_revenue_${index}`}
                                              value={source.total_gross_revenue}
                                              onChange={(ev) =>
                                                  handleListChange(ev, `${dataKey}.sources`, 'total_gross_revenue', index)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3 border-btm" controlId={`frequency_${index}`}>
                            <Form.Label column sm={4}>Frequency</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text" placeholder="" name={`frequency_${index}`}
                                              value={source.frequency}
                                              onChange={(ev) =>
                                                  handleListChange(ev, `${dataKey}.sources`, 'frequency', index)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3 border-btm" controlId={`selling_price_per_unit_${index}`}>
                            <Form.Label column sm={8}>Selling price per unit</Form.Label>
                            <Col sm={4}>
                                <Form.Control type="text" placeholder="" name={`selling_price_per_unit_${index}`}
                                              value={source.selling_price_per_unit}
                                              onChange={(ev) =>
                                                  handleListChange(ev, `${dataKey}.sources`, 'selling_price_per_unit', index)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3 border-btm" controlId={`cost_per_unit_${index}`}>
                            <Form.Label column sm={4}>Cost per unit</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text" placeholder="" name={`cost_per_unit_${index}`}
                                              value={source.cost_per_unit}
                                              onChange={(ev) =>
                                                  handleListChange(ev, `${dataKey}.sources`, 'cost_per_unit', index)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3 border-btm" controlId={`units_sold_${index}`}>
                            <Form.Label column sm={4}>Units sold</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text" placeholder="" name={`units_sold_${index}`}
                                              value={source.units_sold}
                                              onChange={(ev) =>
                                                  handleListChange(ev, `${dataKey}.sources`, 'units_sold', index)}/>
                            </Col>
                        </Form.Group>
                    </Col>
                ))}
                <Col xs={6} className="member-section">
                    <a href="#"
                       onClick={(ev) => handleAddToList(ev, `${dataKey}.sources`, "income_details")}
                       className="btn btn-success"><FontAwesomeIcon icon={faPlus}/>&nbsp;</a>
                </Col>
            </Row>
            <Form.Group as={Row} className="mb-3" controlId="total_revenue">
                <Form.Label column sm={6}>Total Revenue</Form.Label>
                <Col sm={6}>
                    <Form.Control type="text" placeholder="" name="total_revenue"
                                  value={income_details.total_revenue}
                                  onChange={(ev) =>
                                      handleOnChange(ev, `${dataKey}.total_revenue`)}/>
                </Col>
            </Form.Group>
            <Row>
                <Col xs={12}>
                    <span className="lead">Borrower Stated Income</span>
                </Col>
                <Col xs={12}>
                    <Table striped className="mb-3">
                        <tbody>
                        {income_details.stated_income && income_details.stated_income.data &&
                            income_details.stated_income.data.map((items, index) => (
                                <tr key={`stated_inc_${index}`}>
                                    <td>{items[0]}</td>
                                    <td>
                                        <Form.Control type="text" placeholder=""
                                                      name={`stated_income_${index}_1`}
                                                      value={items[1]}
                                                      onChange={(ev) => handleListOfListChange(ev, `${dataKey}.stated_income.data`, index, 1)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Col>
    )
}

export default IncomeDetails;