import {getTitle} from "../../utils/TextUtil";
import Accordion from "react-bootstrap/Accordion";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function LoanDetails({dataKey, loan_details}) {
    return (
        <Accordion.Item eventKey={dataKey} key={dataKey}>
            <Accordion.Header>{getTitle(dataKey)}</Accordion.Header>
            <Accordion.Body>
                <Row>
                    <Col xs={12}>
                        <table className="table table-striped">
                            <tbody>
                            <tr>
                                <th>Constitution</th>
                                <td>{loan_details.constitution}</td>
                            </tr>
                            <tr>
                                <th>Quantum</th>
                                <td>{loan_details.quantum}</td>
                            </tr>
                            <tr>
                                <th>Business Model</th>
                                <td>{loan_details.business_model}</td>
                            </tr>
                            <tr>
                                <th>Industry</th>
                                <td>{loan_details.industry}</td>
                            </tr>
                            <tr>
                                <th>Purpose</th>
                                <td>{loan_details.purpose}</td>
                            </tr>
                            <tr>
                                <th>Tenure</th>
                                <td>{loan_details.tenure}</td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    )
}

export default LoanDetails;
