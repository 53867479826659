// src/pages/organisatio/OrganisationView.js

import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Nav} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useData} from "../../context/DataContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faReceipt, faLandmark, faUser, faFile} from "@fortawesome/free-solid-svg-icons";
import {jsonReq} from "../../utils/HttpUtil";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

function OrganisationView() {
    const { orgRole } = useData();
    const navigate = useNavigate();
    const {org_id} = useParams()

    const [loading, setLoading] = useState(true);
    const [organisation, setOrganisation] = useState(null);
    const [isOrg, setIsOrg] = useState(() => !!organisation);
    const [error, setError] = useState(null);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const fetchOrg = async () => {
            try {
                const res = await jsonReq().get("/dashboard/organisations/get-for-edit?org_id="+org_id);
                setOrganisation(res.data.data.organisation);
                setIsOrg(true)
            }catch (e) {
                console.log("Error fetching organisation: ", e);
                setError(e);
            }finally {
                setLoading(false);
            }
        }
        if (!isOrg) {
            fetchOrg()
        } else {
            setLoading(false);
        }
    }, [isOrg])

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    if (error) {
        return (<p>Error: {error.message}</p>);
    }

    const handleProducts = (ev) => {
        ev.preventDefault();
        const data = { sel_org: organisation };
        navigate('/products/list', { state: data });
    };

    const handleBranches = (ev) => {
        ev.preventDefault();
        const data = { sel_org: organisation };
        navigate('/branches/list', { state: data });
    };

    const handleApplications = (ev) => {
        ev.preventDefault();
        const data = { sel_org: organisation };
        navigate('/applications/list', { state: data });
    };

    const handleUsers = (ev) => {
        ev.preventDefault();
        const data = { sel_org: organisation };
        navigate('/users/list', { state: data });
    };

    const handleTestUsers = (ev) => {
        ev.preventDefault();
        const data = { sel_org: organisation };
        navigate('/test-users/list', { state: data });
    };

    const publishOrganisation = (ev) => {
        ev.preventDefault();
        jsonReq().post("/dashboard/organisations/publish", {org_id:org_id}).then(res => {
            console.log(res.data.data.organisation)
            setOrganisation(res.data.data.organisation);
            navigate('/organisations/list')
        }).catch(err => {
            console.log(err);
            setError(err);
            setShowAlert(true);
        })
    }

    let publish_org = "";
    if (organisation.status === 'dormant') {
        publish_org = <Col xs={12}>
            <Button variant="primary" type="submit" className="btn-lg login-btn-nw" onClick={publishOrganisation}>
                Publish Organisation
            </Button>
        </Col>
    }

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="page-head">
                        <span className="lead">Organisation</span>
                        <Nav className="ml-auto justify-content-end">
                            <Nav.Link href="/organisations/list"><FontAwesomeIcon icon={faList}/>&nbsp;&nbsp;List</Nav.Link>
                            <Nav.Link href="/applications/list" onClick={handleApplications}><FontAwesomeIcon icon={faFile}/>&nbsp;&nbsp;Applications</Nav.Link>
                            <Nav.Link href="#" onClick={handleProducts}><FontAwesomeIcon icon={faReceipt}/>&nbsp;&nbsp;Products</Nav.Link>
                            <Nav.Link href="#" onClick={handleBranches}><FontAwesomeIcon icon={faLandmark}/>&nbsp;&nbsp;Branches</Nav.Link>
                            <Nav.Link href="#" onClick={handleUsers}><FontAwesomeIcon icon={faUser}/>&nbsp;&nbsp;Users</Nav.Link>
                            <Nav.Link href="#" onClick={handleTestUsers}><FontAwesomeIcon icon={faUser}/>&nbsp;&nbsp;Test Users</Nav.Link>
                        </Nav>
                    </div>
                </Col>
                <Col xs={12}>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th/>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><strong>Name</strong></td>
                            <td>{organisation.name}</td>
                        </tr>
                        <tr>
                            <td><strong>Organisation ID</strong></td>
                            <td>{organisation._id}</td>
                        </tr>
                        <tr>
                            <td><strong>Type</strong></td>
                            <td>{organisation.type}</td>
                        </tr>
                        <tr>
                            <td><strong>Status</strong></td>
                            <td>{organisation.status}</td>
                        </tr>
                        <tr>
                            <td><strong>Configuration</strong></td>
                            <td><a className="link-dark" href={`/organisations/configuration/view/${organisation._id}`}>Configure</a> </td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col xs={12}>
                    {showAlert && (
                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                            There was an error while saving the Branch <br/>
                            {error.message}
                        </Alert>
                    )}
                </Col>
                {publish_org}
            </Row>
        </Container>
    );
}

export default OrganisationView;
