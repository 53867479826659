import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import React from "react";
import CharacterDetails from "./CharacterDetails";
import Accordion from "react-bootstrap/Accordion";
import {getTitle} from "../../utils/TextUtil";
import Row from "react-bootstrap/Row";
import ExpenseDetails from "./ExpenseDetails";
import Obligations from "./Obligations";
import EmiComfort from "./EmiComfort";
import IncomeDetails from "./IncomeDetails";
import Capabilities from "./Capabilities";
import SWOT from "./SWOT";

function PDAudioComponent({dataKey, pd_audio, handleOnChange, handleListChange, handleListOfListChange,
                              handleAddToList, handleRemoveFromList}) {
    return (
        <Accordion.Item eventKey={dataKey} key={dataKey}>
            <Accordion.Header>{getTitle(dataKey)}</Accordion.Header>
            <Accordion.Body>
                <Row>
                    {pd_audio.character_details && (
                        <CharacterDetails dataKey={`${dataKey}.character_details`}
                                          character_details={pd_audio.character_details}
                                          handleOnChange={handleOnChange}
                                          handleListChange={handleListChange}
                                          handleAddToList={handleAddToList} handleRemoveFromList={handleRemoveFromList}/>
                    )}
                    {pd_audio.expense_details && (
                        <ExpenseDetails dataKey={`${dataKey}.expense_details`}
                                        expense_details={pd_audio.expense_details}
                                        handleOnChange={handleOnChange}
                                        handleListChange={handleListChange}
                                        handleListOfListChange={handleListOfListChange}
                                        handleAddToList={handleAddToList} handleRemoveFromList={handleRemoveFromList}/>
                    )}
                    {pd_audio.obligations && (
                        <Obligations dataKey={`${dataKey}.obligations`}
                                     obligations={pd_audio.obligations}
                                     handleOnChange={handleOnChange}
                                     handleListChange={handleListChange}
                                     handleListOfListChange={handleListOfListChange}
                                     handleAddToList={handleAddToList} handleRemoveFromList={handleRemoveFromList}/>
                    )}
                    {pd_audio.emi_comfort && (
                        <EmiComfort dataKey={`${dataKey}.emi_comfort`}
                                    emi_comfort={pd_audio.emi_comfort}
                                    handleOnChange={handleOnChange}
                                    handleListChange={handleListChange}/>
                    )}
                    {pd_audio.income_details && (
                        <IncomeDetails dataKey={`${dataKey}.income_details`}
                                       income_details={pd_audio.income_details}
                                       handleOnChange={handleOnChange}
                                       handleListChange={handleListChange}
                                       handleListOfListChange={handleListOfListChange}
                                       handleAddToList={handleAddToList} handleRemoveFromList={handleRemoveFromList}/>
                    )}
                    {pd_audio.capabilities && (
                        <Capabilities dataKey={`${dataKey}.capabilities`}
                                      capabilities={pd_audio.capabilities}
                                      handleOnChange={handleOnChange}
                                      handleListChange={handleListChange}
                                      handleListOfListChange={handleListOfListChange}
                                      handleAddToList={handleAddToList} handleRemoveFromList={handleRemoveFromList}/>
                    )}
                    {pd_audio.SWOT && (
                        <SWOT dataKey={`${dataKey}.SWOT`}
                              SWOT={pd_audio.SWOT}
                              handleOnChange={handleOnChange}/>
                    )}
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    )
}

export default PDAudioComponent;