import React, {useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DashboardNav from '../dashboard/DashboardNav';
import OrganisationView from "../organisation/OrganisationView";
import { useData } from '../../context/DataContext'
import DefaultContent from "../dashboard/DefaultContent";
import OrganisationList from '../organisation/OrganisationList';
import OrganisationEdit from "../organisation/OrganisationEdit";
import ProductView from "./ProductView";
import ProductList from "./ProductList";
import ProductEdit from "./ProductEdit";
import Sidebar from "../../components/Sidebar";

const Product = (data) => {

    const { orgRole, fetchHome } = useData();
    const cData = data.data || {};
    const action = cData.cAction ? cData.cAction : "list";

    let mainComp = <DefaultContent data={orgRole}/>;
    if (action === "view") {
        mainComp = <ProductView/>
    } else if(action === 'list') {
        mainComp = <ProductList/>
    } else if(action === 'edit') {
        mainComp = <ProductEdit/>
    }

    useEffect(() => {
        if (!orgRole) {
            fetchHome();
        }
    }, []);

    return (
        <Container fluid>
            <Row>
                <Col xs={12}>
                    <DashboardNav data={orgRole}/>
                </Col>
            </Row>
            <Row className="full-height">
                <Col xs={2}>
                    <Sidebar data={orgRole}/>
                </Col>
                <Col xs={10}>
                    <Container sx={{ margin: 2 }}>
                        {mainComp}
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default Product;
