import useFetch from "../utils/UseFetch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRepeat} from "@fortawesome/free-solid-svg-icons";

const getNested = (obj, path) => {
    return path.split('.').reduce((acc, key) => acc && acc[key], obj);
};

// key is key of object in data that is being fetched
export const ButtonWithRetry = ({ url , dataKey}) => {
    console.log("ButtonWithRetry: ", url, dataKey);
    const { data, loading, error, retry} = useFetch(url, true);

    if (loading) {
        return <div>Loading...</div>
    }

    if (data) {
        if (dataKey) {
            return (
                <button onClick={retry}>
                    {getNested(data, dataKey)}
                    <FontAwesomeIcon icon={faRepeat} />
                </button>
            )
        } else {
            return (
                <button onClick={retry}>
                    {JSON.stringify(data)}
                    <FontAwesomeIcon icon={faRepeat} />
                </button>
            )
        }
    }

    if (error) {
        return (
            <div>
                <div>Error: {error.response ? error.response.data.msg : error.message}</div>
                <button onClick={retry}><FontAwesomeIcon icon={faRepeat} /> </button>
            </div>
        );
    }

}