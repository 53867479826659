import Accordion from "react-bootstrap/Accordion";
import {getTitle} from "../../utils/TextUtil";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

function CoBorrowDetails({dataKey, co_borrower_details}) {
    return (
        <Accordion.Item eventKey={dataKey} key={dataKey}>
            <Accordion.Header>{getTitle(dataKey)}</Accordion.Header>
            <Accordion.Body>
                <Row>
                    <Col xs={12}>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Photo</th>
                                    <th>Name</th>
                                    <th>DOB</th>
                                    <th>Relation</th>
                                    <th>Gender</th>
                                </tr>
                            </thead>
                            <tbody>
                            {co_borrower_details.co_borrower && co_borrower_details.co_borrower.map((cob, idx) => {
                                return (
                                    <tr key={`cob_${idx}`}>
                                        <td>{cob.photo}</td>
                                        <td>{cob.name}</td>
                                        <td>{cob.dob}</td>
                                        <td>{cob.relation}</td>
                                        <td>{cob.gender}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    )
}

export default CoBorrowDetails;
