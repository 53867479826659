import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faMinus} from "@fortawesome/free-solid-svg-icons";

function Loans({dataKey, loans, handleListOfListChange, handleAddToList, handleRemoveFromList}) {
    const [colSpan, setColSpan] = useState(()=> {
        if (loans && loans.headers){
            return loans.headers.length+1;
        }
        return 5
    })

    return (
        <Col xs={12}>
            <span className="sub-heading">{loans.main_header}</span>
            <Table bordered hover>
                <thead>
                    <tr>
                        {loans && loans.headers && loans.headers.map(header => (
                            <th>{header}</th>
                        ))}
                        <td>&nbsp;</td>
                    </tr>
                </thead>
                <tbody>
                {
                    loans.data && loans.data.map((d, index) => (
                        <tr key={`loans_${index}`}>
                            {d.map((val, ix) => (
                                <td key={`loans_data_${index}_${ix}`}>
                                    <Form.Control type="text" placeholder="" name={`loan_data_${index}_${ix}`}
                                                  value={val}
                                                  onChange={(ev) =>
                                                      handleListOfListChange(ev, `${dataKey}.data`, index, ix)}/>
                                </td>
                            ))}
                            <td>
                                <a href="#"
                                   onClick={(ev) => handleRemoveFromList(ev, `${dataKey}.data`, index)}
                                   className="btn btn-danger"><FontAwesomeIcon icon={faMinus}/>&nbsp;</a>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={colSpan}>
                        <a href="#"
                               onClick={(ev) => handleAddToList(ev, `${dataKey}.data`, 'obligations')}
                               className="btn btn-success"><FontAwesomeIcon icon={faPlus}/>&nbsp;</a>
                        </td>
                    </tr>
                </tfoot>
            </Table>
        </Col>
    )
}

function Obligations({
                         dataKey, obligations, handleOnChange, handleListChange, handleListOfListChange,
                         handleAddToList, handleRemoveFromList}) {
    return (
        <Col xs={12}>
            <Row>
                <Col xs={12}><span className="sub-heading">Obligations</span></Col>
                <Col xs={12}>
                    <Form.Group className="mb-3" controlId="obligations_summary">
                        <Form.Label>Summary</Form.Label>
                        <Form.Control as="textarea" name="summary" rows={6}
                                      value={obligations.summary}
                                      onChange={(ev) => handleOnChange(ev, `${dataKey}.summary`)}/>
                    </Form.Group>
                    <Loans dataKey={`${dataKey}.loans`} loans={obligations.loans}
                           handleListOfListChange={handleListOfListChange}
                           handleAddToList={handleAddToList}
                           handleRemoveFromList={handleRemoveFromList}/>
                </Col>
            </Row>
        </Col>
    )
}

export default Obligations;