// src/pages/users/UserView.js

import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Nav} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useData} from "../../context/DataContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faEdit} from "@fortawesome/free-solid-svg-icons";
import {jsonReq} from "../../utils/HttpUtil";
import Spinner from "react-bootstrap/Spinner";

function UserView() {
    const location = useLocation();
    const { orgRole } = useData();
    const navigate = useNavigate();
    const {user_id} = useParams()
    const [selOrg] = useState(() => {
        if (location.state) {
            return location.state.sel_org
        }
        return orgRole.organisation;
    })
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const res = await jsonReq().get("/dashboard/users/view?id="+user_id);
                setUser(res.data.data.user);
            }catch (e) {
                console.log("Error fetching user: ", e);
                setError(e);
            }finally {
                setLoading(false);
            }
        }
        fetchUser()
    }, [])

    const goToUserList = (ev) => {
        ev.preventDefault();
        const data = { sel_org: selOrg };
        navigate('/users/list', { state: data });
    };

    const editUser = (ev) => {
        ev.preventDefault();
        const data = { sel_org: selOrg, user: user };
        navigate(`/users/edit/${user_id}`, { state: data });
    };

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    if (error) {
        return (<p>Error: {error.message}</p>);
    }

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="page-head">
                        <span className="lead">Organisation - {selOrg.name} ({selOrg._id})</span>
                        <Nav className="ml-auto justify-content-end">
                            <Nav.Link href="/users/list" onClick={goToUserList}><FontAwesomeIcon
                                icon={faList}/>&nbsp;&nbsp;List</Nav.Link>
                            <Nav.Link href={`/users/edit/${user_id}`} onClick={editUser}><FontAwesomeIcon
                                icon={faEdit}/>&nbsp;&nbsp;Edit</Nav.Link>
                        </Nav>
                    </div>
                </Col>
                <Col xs={12}>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th/>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><strong>Status</strong></td>
                            <td>{user.status}</td>
                        </tr>
                        <tr>
                            <td><strong>Last Login</strong></td>
                            <td>{new Date(user.last_login * 1000).toLocaleDateString()}</td>
                        </tr>
                        <tr>
                            <td><strong>Is Org Admin</strong></td>
                            <td>{user.org_admin ? 'Yes' : 'No'}</td>
                        </tr>
                        <tr>
                            <td><strong>Name</strong></td>
                            <td>{user.name}</td>
                        </tr>
                        <tr>
                            <td><strong>Email</strong></td>
                            <td>{user.email}</td>
                        </tr>
                        <tr>
                            <td><strong>Phone Number</strong></td>
                            <td>{user.mobile}</td>
                        </tr>
                        <tr>
                            <td><strong>Roles</strong></td>
                            <td>{
                                user.roles.map(rl => rl.display_name).join()
                            }</td>
                        </tr>
                        <tr>
                            <td><strong>Branch</strong></td>
                            <td>{`${user.branch.name} (${user.branch.branch_id})`}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default UserView;
