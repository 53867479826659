// src/components/Organisation.js

import React, {useEffect} from 'react';
import DashboardNav from '../dashboard/DashboardNav';
import {useData} from '../../context/DataContext';
import DefaultContent from '../dashboard/DefaultContent';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "../../components/Sidebar";
import BranchView from "./BranchView";
import BranchList from "./BranchList";
import BranchEdit from "./BranchEdit";

const Branch = (data) => {
    const {orgRole, fetchHome} = useData();
    const cData = data.data || {};
    const component = cData.component ? cData.component : 'organisation';
    const action = cData.cAction ? cData.cAction : 'view';

    let mainComp = <DefaultContent data={orgRole}/>;
    if (action === 'view') {
        mainComp = <BranchView data={orgRole}/>;
    } else if (action === 'list') {
        mainComp = <BranchList data={orgRole}/>;
    } else if (action === 'edit') {
        mainComp = <BranchEdit/>;
    }

    useEffect(() => {
        if (!orgRole) {
            fetchHome();
        }
    }, [orgRole, fetchHome]);

    return (
        <Container fluid>
            <Row>
                <Col xs={12}>
                    <DashboardNav data={orgRole}/>
                </Col>
            </Row>
            <Row className="full-height">
                <Col xs={2}>
                    <Sidebar data={orgRole}/>
                </Col>
                <Col xs={10}>
                    <Container sx={{ margin: 2 }}>
                        {mainComp}
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default Branch;
