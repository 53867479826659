// src/components/BranchEdit.js

import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Nav} from "react-bootstrap";
import Multiselect from 'multiselect-react-dropdown';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {getErrorMessage, isEmpty} from "../../utils/TextUtil";
import {jsonReq} from "../../utils/HttpUtil";
import {useData} from "../../context/DataContext";
import useFetch from "../../utils/UseFetch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";
import Ajv from 'ajv'
import Spinner from "react-bootstrap/Spinner";
import '../../css/dashboard.css'
import Table from "react-bootstrap/Table";

function TestUserEdit() {
    const location = useLocation();
    const navigate = useNavigate();
    const { orgRole } = useData();
    const [selOrg] = useState(() => {
        if (location.state) {
            return location.state.sel_org
        } else {
            return orgRole.organisation;
        }
    })

    const [showAlert, setShowAlert] = useState(false);
    const [submitError, setSubmitError] = useState("");
    const [roles, setRoles] = useState(null);
    const [branches, setBranches] = useState(null);
    const [mockUser, setMockUser] = useState(null)
    const [success, setSuccess] = useState(false)
    const [hasRoles, setHasRoles] = useState(() => {
        return (roles && roles.length > 0);
    });

    const [hasBranches, setHasBranches] = useState(() => {
        return (branches && branches.length > 0);
    });

    const [mockUserForm, setMockUserForm] = useState(() => {
        return {
            name: "",
            org_admin: false,
            roles: [],
            branch_id: "",
            branches: []
        };
    });

    const {data: roleData, loading: roleLoading, error: roleError} =
        useFetch(`/dashboard/roles/list?org_id=${selOrg._id}`, !hasRoles);

    const {data: branchData, loading: branchLoading, error: branchError} =
        useFetch(`/dashboard/branches/list?org_id=${selOrg._id}`, !hasBranches);

    useEffect(() => {
        if (roleData) {
            setRoles(roleData.roles);
            setHasRoles(true)
        }
    }, [roleData]);

    useEffect(() => {
        if (branchData) {
            setBranches(branchData.branches);
            setHasBranches(true)
        }
    }, [branchData]);

    if (roleLoading || branchLoading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    if (roleError || branchError) {
        let e = roleError || branchError
        return <p>Error: {e.message}</p>;
    }

    const userSchema = {
        type: 'object',
        properties: {
            name: {type: 'string'},
            org_admin: {type: 'boolean'},
            roles: {type: 'array', items: {type: 'string'}},
            branch_id: {type: 'string', pattern: "[a-zA-Z0-9]+"},
            branches: {type: 'array', items: {type: 'string'}},
        },
        required: ['name', 'branch_id']
    }
    const ajv = new Ajv();
    const validate = ajv.compile(userSchema)

    const handleChange = (ev) => {
        const {name, value} = ev.target;
        if (name.includes(".")) {
            const [section, field] = name.split('.');
            setMockUserForm(prevState => ({
                ...prevState,
                [section]: {
                    ...prevState[section],
                    [field]: value
                }
            }));
        } else {
            setMockUserForm({
                ...mockUserForm,
                [name]: value
            });
        }
        setShowAlert(false);
    };

    const handleOrgAdminChange = (ev) => {
        // ev.preventDefault();
        const {name, checked} = ev.target;
        setMockUserForm({
            ...mockUserForm,
            [name]: checked
        })
        setShowAlert(false);
    }

    const addRoleToUser = (selectedList, selectedItem) => {
        setMockUserForm({
            ...mockUserForm,
            roles: selectedList.map(sl => sl.id)
        })
    }

    const removeRoleFromUser = (selectedList, removedItem) => {
        setMockUserForm({
            ...mockUserForm,
            roles: selectedList.map(sl => sl.id)
        })
    }

    const addBranchToUser = (selectedList, selectedItem) => {
        setMockUserForm({
            ...mockUserForm,
            branches: selectedList.map(sl => sl.id)
        })
    }

    const removeBranchFromUser = (selectedList, removedItem) => {
        setMockUserForm({
            ...mockUserForm,
            branches: selectedList.map(sl => sl.id)
        })
    }

    const validateForm = () => {
        let show = false;
        let err = "";
        // convert string to number
        const valid = validate(mockUserForm);
        if (!valid) {
            show = true;
            err = validate.errors[0].instancePath+" "+validate.errors[0].message
        }
        return [show, err];
    };

    const handleSubmit = (ev) => {
        ev.preventDefault();
        const [show, err] = validateForm();
        if (show) {
            setSubmitError(err);
            setShowAlert(true);
            return
        }
        //call create api
        const url = "/dashboard/users/add-test-user";

        jsonReq().post(url, {org_id: selOrg._id, user_details: mockUserForm}).then(res => {
            console.log("Mock user saved successfully: ", res.data.data.mock_user);
            // todo: show details
            setMockUser(res.data.data.mock_user);
            setSuccess(true);
        }).catch(er => {
            setSubmitError(getErrorMessage(er));
            setShowAlert(true);
            console.log(submitError);
        })
    };

    let roleOptions = []
    let branchesOptions = []
    if (roles && roles.length > 0) {
        roleOptions = roles.map(role => ({name: role.display_name, id: role._id}));
    }
    if (branches && branches.length > 0) {
        branchesOptions = branches.map(branch => ({name: branch.name, id: branch._id}));
    }

    let branchOptions = [];
    if (branches && branches.length > 0) {
        branches.forEach(br => {
            branchOptions.push(
                <option value={br._id} key={br._id}>{br.name}</option>
            )
        })
    }

    const backToMockUserList = (ev) => {
        ev.preventDefault();
        const data = { sel_org: selOrg };
        navigate('/test-users/list', { state: data });
    };

    const successComp = (
        <Col xs={12}>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th colspan={2}>Test User Details</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Name</th>
                        <td>{mockUser?.name}</td>
                    </tr>
                    <tr>
                        <th>Email</th>
                        <td>{mockUser?.email}</td>
                    </tr>
                    <tr>
                        <th>Phone No</th>
                        <td>{mockUser?.mobile}</td>
                    </tr>
                    <tr>
                        <th>Is Org Admin</th>
                        <td>{mockUser?.org_admin ? 'Yes' : 'No'}</td>
                    </tr>
                    {/*<tr>*/}
                    {/*    <th>OTP</th>*/}
                    {/*    <td>{mockUser?.otp}</td>*/}
                    {/*</tr>*/}
                </tbody>
            </Table>
        </Col>
    )

    const mockUserHtmlForm = (
        <Col xs={12} className="border border-1 p-3">
            <div className="clearfix">
                <div className="col-12 lead fs-4 fw-bold float-start">
                    <span >Add/Edit User</span>
                </div>
            </div>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="user_name">
                    <Form.Label className="lead">Name</Form.Label>
                    <Form.Control type="text" placeholder="" name="name" value={mockUserForm.name}
                                  onChange={handleChange}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="user_org_admin">
                    <Form.Label className="lead">Is Organisation Admin</Form.Label>
                    <Form.Check className="checkbox-custom" type="checkbox" onChange={handleOrgAdminChange} name="org_admin" checked={mockUserForm.org_admin}/>
                </Form.Group>
                {mockUserForm.org_admin && (
                    <fieldset>
                        <Form.Group className="mb-3" controlId="user_branches">
                            <Form.Label className="lead">Select branches that can be accessed by the user</Form.Label>
                            <Multiselect className="white-bg" options={branchesOptions} selectedValues={[]} displayValue="name" placeholder="Select branches" onSelect={addBranchToUser} onRemove={removeBranchFromUser}/>
                        </Form.Group>
                    </fieldset>
                )}
                <Form.Group className="mb-3" controlId="user_branch_id">
                    <Form.Label className="lead">Branch</Form.Label>
                    <Form.Select name="branch_id" onChange={handleChange} value={mockUserForm.branch_id}>
                        {branchOptions}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="user_roles">
                    <Form.Label className="lead">User Roles</Form.Label>
                    <Multiselect className="white-bg" options={roleOptions} selectedValues={[]} displayValue="name" placeholder="Select roles" onSelect={addRoleToUser} onRemove={removeRoleFromUser}/>
                </Form.Group>
                <Button variant="primary" type="submit" className="btn-lg login-btn-nw">
                    Create Test User
                </Button>
            </Form>
        </Col>
    )

    return (
        <Container>
            <Row>
                <Col xs={12} className="page-head">
                    <div className="page-head">
                        <span className="lead">Organisation - {selOrg.name} ({selOrg._id})</span>
                        <Nav className="ml-auto justify-content-end">
                            <Nav.Link href="/test-users/list" onClick={backToMockUserList}><FontAwesomeIcon
                                icon={faList}/>&nbsp;&nbsp;List</Nav.Link>
                        </Nav>
                    </div>
                </Col>
                <Col xs={12}>
                    {showAlert && (
                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                            There was an error while saving the User <br/>
                            {submitError}
                        </Alert>
                    )}
                </Col>
                {success ? successComp : mockUserHtmlForm}
            </Row>
        </Container>
    );
}

export default TestUserEdit;
