// src/components/Sidebar.js

import React from 'react';
import { Nav } from 'react-bootstrap';
// import {useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReceipt, faLandmark, faUser, faLock, faFile, faChartSimple } from '@fortawesome/free-solid-svg-icons'

function Sidebar(data) {
    const roles = data.data.roles;
    // const org = data.data.organisation;
    // const navigate = useNavigate();

    // TODO: Set icons
    const icons = {
        branch: faLandmark,
        product: faReceipt,
        role: faLock,
        user: faUser,
        application: faFile,
        cams: faChartSimple
    }

    const links = [];
    for (const role of roles) {
        if (role.visible) {
            links.push(
                <Nav.Item className='nav-item-nw' key={role.name}>
                    <Nav.Link href={role.path}><FontAwesomeIcon icon={icons[role.name]} />&nbsp;{role.label}</Nav.Link>
                </Nav.Item>
            )
        }
    }

    return (
        <div className="Sidebar">
            <Nav className="flex-column mt-3">
                {links}
            </Nav>
        </div>
    );
}

export default Sidebar;
