import {getTitle} from "../../utils/TextUtil";
import Accordion from "react-bootstrap/Accordion";
import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

function LifestyleComponent({dataKey, lifestyle_images, handleOnChange, handleListChange, handleListOfListChange,
                                handleAddToList, handleRemoveFromList}) {
    const [colSpan, setColSpan] = useState(()=> {
        if (lifestyle_images.inventory && lifestyle_images.inventory.headers){
            return lifestyle_images.inventory.headers.length+1;
        }
        return 5
    })
    return (
        <Accordion.Item eventKey={dataKey} key={dataKey}>
            <Accordion.Header>{getTitle(dataKey)}</Accordion.Header>
            <Accordion.Body>
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="summary">
                            <Form.Label>Summary</Form.Label>
                            <Form.Control as="textarea" name="lifestyle_summary" rows={12}
                                          value={lifestyle_images.summary}
                                          onChange={(ev) => handleOnChange(ev, `${dataKey}.summary`)}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <span className="sub-heading">Lifestyle Inventory</span>
                        <Table bordered hover>
                            <thead>
                            <tr>
                                {lifestyle_images.inventory && lifestyle_images.inventory.headers &&
                                    lifestyle_images.inventory.headers.map((item, index) => (
                                        <th key={`life_inventory_h_${index}`}>{item}</th>
                                    ))}
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            {lifestyle_images.inventory && lifestyle_images.inventory.data && lifestyle_images.inventory.data.map((items, index) => (
                                <tr key={`life_inventory_${index}`}>
                                    {items.map((item, ix) => (
                                        <td key={`life_inventory_${index}_${ix}`}>
                                            <Form.Control type="text" placeholder=""
                                                          name={`life_inventory_${index}_${ix}`}
                                                          value={item}
                                                          onChange={(ev) => handleListOfListChange(ev, `${dataKey}.inventory.data`, index, ix)}/>
                                        </td>
                                    ))}
                                    <td>
                                        <a href="#"
                                           onClick={(ev) => handleRemoveFromList(ev, `${dataKey}.inventory.data`, index)}
                                           className="btn btn-danger"><FontAwesomeIcon icon={faMinus}/>&nbsp;</a>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan={colSpan}>
                                    <a href="#"
                                       onClick={(ev) => handleAddToList(ev, `${dataKey}.inventory.data`, 'inventory')}
                                       className="btn btn-success"><FontAwesomeIcon icon={faPlus}/>&nbsp;</a>
                                </td>
                            </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    )
}

export default LifestyleComponent;