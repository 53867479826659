// src/components/BranchEdit.js

import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Nav} from "react-bootstrap";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {isEmpty} from "../../utils/TextUtil";
import {jsonReq} from "../../utils/HttpUtil";
import {useData} from "../../context/DataContext";
import useFetch from "../../utils/UseFetch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";
import Ajv from 'ajv'
import Spinner from "react-bootstrap/Spinner";

function BranchEdit() {
    const location = useLocation();
    const navigate = useNavigate();
    const { orgRole } = useData();
    const {branch_id} = useParams()
    const [selOrg, setSelOrg] = useState(() => {
        if (location.state) {
            return location.state.sel_org
        } else {
            return orgRole.organisation;
        }
    })
    const [branch, setBranch] = useState(() => {
        if (location.state) {
            return location.state.branch;
        } else {
            return null;
        }
    });
    const [toFetch, setToFetch] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [submitError, setSubmitError] = useState("");
    const [branchForm, setBranchForm] = useState(() => {
        if (branch) {
            return branch
        }
        return {
            _id: "",
            name: "",
            branch_id: "",
            city: "",
            state: "",
            address: "",
        };
    });

    useEffect(() => {
        if (!branch && branch_id) {
            setToFetch(true);
        }
    }, [toFetch])

    const {data, loading, error} = useFetch(`/dashboard/branches/view?id=${branch_id}`, toFetch);

    useEffect(() => {
        if (data) {
            setBranch(data.branch);
            setToFetch(false)
        }
    }, [data]);

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    if (error) {
        return <p>Error: {error.message}</p>;
    }

    const branchSchema = {
        type: 'object',
        properties: {
            name: {type: 'string'},
            branch_id: {type: 'string'},
            city: {type: 'string'},
            state: {type: 'string'},
            address: {type: 'string'},
        },
        required: ['name', 'branch_id', 'city', 'state']
    }
    const ajv = new Ajv();
    const validate = ajv.compile(branchSchema)

    const handleChange = (ev) => {
        const {name, value} = ev.target;
        if (name.includes(".")) {
            const [section, field] = name.split('.');
            setBranchForm(prevState => ({
                ...prevState,
                [section]: {
                    ...prevState[section],
                    [field]: value
                }
            }));
        } else {
            setBranchForm({
                ...branchForm,
                [name]: value
            });
        }
        setShowAlert(false);
    };

    const validateForm = () => {
        let show = false;
        let err = "";
        // convert string to number
        const valid = validate(branchForm);
        if (!valid) {
            show = true;
            err = validate.errors[0].instancePath+" "+validate.errors[0].message
        }
        return [show, err];
    };

    const handleSubmit = (ev) => {
        ev.preventDefault();
        const [show, err] = validateForm();
        if (show) {
            setShowAlert(true);
            setSubmitError(err);
            return
        }
        //call create api
        let url = "/dashboard/branches/create";
        if (!isEmpty(branchForm._id)){
            url = "/dashboard/branches/update";
        }
        jsonReq().post(url, {org_id: selOrg._id, branch_details: branchForm}).then(res => {
            console.log("Branch saved successfully: ", res.data.data.branch);
            const uBranch = res.data.data.branch;
            navigate(`/branches/view/${uBranch._id}`, {state: {branch: uBranch, sel_org: selOrg}})
        }).catch(er => {
            setShowAlert(true);
            setSubmitError(er.message);
        })
    };

    const handleBranchList = (ev) => {
        ev.preventDefault();
        const data = { sel_org: selOrg };
        navigate('/branches/list', { state: data });
    };

    return (
        <Container>
            <Row>
                <Col xs={12} className="page-head">
                    <div className="page-head">
                        <span className="lead">Organisation - {selOrg.name} ({selOrg._id})</span>
                        <Nav className="ml-auto justify-content-end">
                            <Nav.Link href="/branches/list" onClick={handleBranchList}><FontAwesomeIcon
                                icon={faList}/>&nbsp;&nbsp;List</Nav.Link>
                        </Nav>
                    </div>
                </Col>
                <Col xs={12}>
                    {showAlert && (
                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                            There was an error while saving the Branch <br/>
                            {submitError}
                        </Alert>
                    )}
                </Col>
                <Col xs={12} className="border border-1 p-3">
                    <div className="clearfix">
                        <div className="col-12 lead fs-4 fw-bold float-start">
                            <span >Add/Edit Branch</span>
                        </div>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Control type="hidden" placeholder="" name="_id" value={branchForm._id}/>
                        <Form.Group className="mb-3" controlId="branch_name">
                            <Form.Label className="lead">Branch Name</Form.Label>
                            <Form.Control type="text" placeholder="" name="name" value={branchForm.name}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="branch_id">
                            <Form.Label className="lead">Branch ID</Form.Label>
                            <Form.Control type="text" placeholder="" name="branch_id" value={branchForm.branch_id}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="branch_city">
                            <Form.Label className="lead">Branch City</Form.Label>
                            <Form.Control type="text" placeholder="" name="city" value={branchForm.city}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="branch_state">
                            <Form.Label className="lead">Branch State</Form.Label>
                            <Form.Control type="text" placeholder="" name="state" value={branchForm.state}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="branch_address">
                            <Form.Label className="lead">Branch Address</Form.Label>
                            <Form.Control type="text" placeholder="" name="address" value={branchForm.address}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="btn-lg login-btn-nw">
                            Save Branch
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default BranchEdit;
