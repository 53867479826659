
const Storage = function () {
    this.env = process.env.NODE_ENV || 'development';
    console.log("Environment for store: ", this.env);
}

Storage.prototype.setValue = function (key, value) {
    localStorage.setItem(`${this.env}_${key}`, JSON.stringify(value));
}

Storage.prototype.getValue = function (key) {
    let val = localStorage.getItem(`${this.env}_${key}`);
    if (val && val !== "undefined") {
        return JSON.parse(val);
    }
    return null;
}

Storage.prototype.clear = function () {
    for (const k in localStorage) {
        const key = `${this.env}_${k}`
        if (localStorage.hasOwnProperty(key)) {
            localStorage.removeItem(key)
        }
    }
}

export const appStore = new Storage()
