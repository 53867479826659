import Col from "react-bootstrap/Col";
import React from "react";
import {Table} from "@mui/material";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

function Capabilities({dataKey, capabilities, handleOnChange, handleListChange, handleListOfListChange,
                          handleAddToList, handleRemoveFromList}) {
    return (
        <Col xs={12}>
            <span className="main-heading">Capabilities</span>
            {capabilities.income_calculations && (
                <Row>
                    <Col xs={12}><span className="sub-heading">{capabilities.income_calculations.main_header}</span></Col>
                    <Col xs={12}>
                        <Table striped bordered hover className="border-full">
                            <thead>
                            <tr>
                                {capabilities.income_calculations && capabilities.income_calculations.headers &&
                                    capabilities.income_calculations.headers.map(header => (<th>{header}</th>))}
                            </tr>
                            </thead>
                            <tbody>
                            {capabilities.income_calculations && capabilities.income_calculations.data &&
                                capabilities.income_calculations.data.map((dt, index) => (
                                    <tr key={`income_calc_${index}`}>
                                        <td>{dt[0]}</td>
                                        <td>
                                            <Form.Control readOnly={[2, 4, 7].includes(index)} type="text" name={`ic_${index}_1`}
                                                          value={dt[1]}
                                                          onChange={[2, 4, 7].includes(index) ? null :
                                                              (ev) =>
                                                                  handleListOfListChange(ev, `${dataKey}.income_calculations.data`, index, 1, true)}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
            {capabilities.dscr && (
                <Row>
                    <Col xs={12}><span className="sub-heading">{capabilities.dscr.main_header}</span></Col>
                    <Col xs={12}>
                        <Table striped bordered hover className="border-full">
                            <thead>
                            <tr>
                                {capabilities.dscr.headers &&
                                    capabilities.dscr.headers.map(header => (<th>{header}</th>))}
                            </tr>
                            </thead>
                            <tbody>
                            {capabilities.dscr.data &&
                                capabilities.dscr.data.map((dt, index) => (
                                    <tr key={`dscr_${index}`}>
                                        <td>{dt[0]}</td>
                                        <td>
                                            <Form.Control type="text" name={`dscr_${index}_1`}
                                                          readOnly={[0, 4, 6, 7].includes(index)}
                                                          value={dt[1]}
                                                          onChange={[0, 4, 6, 7].includes(index) ? null : (ev) => handleListOfListChange(ev, `${dataKey}.dscr.data`, index, 1, true)}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
            {capabilities.foir && (
                <Row>
                    <Col xs={12}><span className="sub-heading">{capabilities.foir.main_header}</span></Col>
                    <Col xs={12}>
                        <Table striped bordered hover className="border-full">
                            <thead>
                            <tr>
                                {capabilities.foir.headers &&
                                    capabilities.foir.headers.map(header => (<th>{header}</th>))}
                            </tr>
                            </thead>
                            <tbody>
                            {capabilities.foir.data &&
                                capabilities.foir.data.map((dt, index) => (
                                    <tr key={`foir_${index}`}>
                                        <td>{dt[0]}</td>
                                        <td>
                                            <Form.Control type="text" name={`foir_${index}_1`}
                                                          readOnly={[0, 2, 5, 6].includes(index)}
                                                          value={dt[1]}
                                                          onChange={[0, 2, 5, 6].includes(index) ? null :
                                                              (ev) => handleListOfListChange(ev, `${dataKey}.foir.data`, index, 1, true)}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
        </Col>
    )
}

export default Capabilities;