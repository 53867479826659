// src/components/OrganisationList.js

import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from "react-bootstrap/Table";
import { jsonReq } from "../../utils/HttpUtil";
import Spinner from 'react-bootstrap/Spinner';
import {Nav} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {useData} from "../../context/DataContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

function BranchList() {
    const [branches, setBranches] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { orgRole } = useData();
    const location = useLocation();
    const [selOrg, setSelOrg] = useState(() => {
        if (location.state) {
            return location.state.sel_org
        } else {
            return orgRole.organisation;
        }
    })

    useEffect(() => {
        const fetchBranches = async () => {
            try {
                const res = await jsonReq().get("/dashboard/branches/list?org_id="+selOrg._id);
                setBranches(res.data.data.branches);
            }catch (e) {
                console.log("Error fetching organisation list: ", e);
                setError(e);
            }finally {
                setLoading(false);
            }
        }
        fetchBranches()
    }, []);

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    if (error) {
        return (<p>Error: {error.message}</p>);
    }

    const handleView = (ev, bid) => {
        ev.preventDefault();
        const d = { sel_org: selOrg};
        navigate(`/branches/view/${bid}`, {state: d});
    };

    let branchComps = [];
    let i=1;
    branches.forEach(branch => {
        branchComps.push(
            <tr key={branch._id}>
                <td>{i}</td>
                <td>{branch.branch_id}</td>
                <td>{branch.name}</td>
                <td>{branch.city}</td>
                <td>{branch.state}</td>
                <td>
                    <Nav>
                        <Nav.Link href={`/branches/view/${branch._id}`} data-id={branch._id} onClick={(ev) => handleView(ev, branch._id)}>View</Nav.Link>
                    </Nav>
                </td>
            </tr>
        );
        i++;
    });

    const handleBranchAdd = (ev) => {
        ev.preventDefault();
        const d = { sel_org: selOrg };
        navigate("/branches/edit/", {state: d});
    };

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="page-head">
                        <span className="lead">Organisation - {selOrg.name} ({selOrg._id})</span>
                        <Nav className="ml-auto justify-content-end">
                            <Nav.Link href="#" onClick={handleBranchAdd}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;New</Nav.Link>
                        </Nav>
                    </div>

                </Col>
                <Col xs={12}>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Branch ID</th>
                            <th>Name</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {branchComps}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default BranchList;
