
const isEmpty = function (val) {
    return !val || val === '' || val.length < 1;
};

const getErrorMessage = function(err) {
    console.log("err code: ", err.code);
    if (err.code === 'ERR_BAD_REQUEST') {
        return err.response.data.msg;
    }
    return err.message;
}

const getTitle = (str) => {
    if (!str) {
        return "";
    }
    return str
        .split('_')                  // Split by underscore
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())  // Capitalize first letter
        .join(' ');
}

export {
    isEmpty,
    getErrorMessage,
    getTitle,
}