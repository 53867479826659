// src/components/ProductEdit.js

import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Nav} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {isEmpty} from "../../utils/TextUtil";
import {jsonReq} from "../../utils/HttpUtil";
import {useData} from "../../context/DataContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";
import Ajv from 'ajv'

function ProductEdit() {
    const location = useLocation();
    const navigate = useNavigate();
    const { orgRole } = useData();
    const {product_id} = useParams()
    const [selOrg] = useState(() => {
        if (location.state) {
            return location.state.sel_org
        } else {
            return orgRole.organisation;
        }
    })
    const [product] = useState(() => {
        if (location.state) {
            return location.state.product;
        } else {
            return null;
        }
    });

    const [showAlert, setShowAlert] = useState(false);
    const [submitError, setSubmitError] = useState("");
    const [productForm, setProductForm] = useState(() => {
        if (product) {
            return product
        }
        return {
            _id: "",
            name: "",
            code: "",
            roi: {},
            tenure: {},
            amount: {},
            tnc: ""
        };
    });

    const productSchema = {
        type: 'object',
        properties: {
            name: {type: 'string'},
            roi: {
                type: 'object',
                properties: {
                    min: {type: 'integer'},
                    max: {type: 'integer'},
                },
                required: ['min', 'max']
            },
            tenure: {
                type: 'object',
                properties: {
                    min: {type: 'integer'},
                    max: {type: 'integer'},
                },
                required: ['min', 'max']
            },
            amount: {
                type: 'object',
                properties: {
                    min: {type: 'integer'},
                    max: {type: 'integer'},
                },
                required: ['min', 'max']
            },
            tnc: {type: 'string'}
        },
        required: ['name', 'roi', 'tenure', 'amount']
    }
    const ajv = new Ajv();
    const validate = ajv.compile(productSchema)

    const handleChange = (ev) => {
        const {name, value} = ev.target;
        //try parsing to int if possible
        let vl = parseInt(value, 10)
        if (isNaN(vl)) {
            vl = value;
        }
        if (name.includes(".")) {
            const [section, field] = name.split('.');
            setProductForm(prevState => ({
                ...prevState,
                [section]: {
                    ...prevState[section],
                    [field]: vl
                }
            }));
        } else {
            setProductForm({
                ...productForm,
                [name]: vl
            });
        }
        setShowAlert(false);
    };

    const validateForm = () => {
        let show = false;
        let err = "";
        // convert string to number
        const valid = validate(productForm);
        if (!valid) {
            show = true;
            err = validate.errors[0].instancePath+" "+validate.errors[0].message
        }
        return [show, err];
    };

    const handleSubmit = (ev) => {
        ev.preventDefault();
        const [show, err] = validateForm();
        if (show) {
            setShowAlert(true);
            setSubmitError(err);
            return
        }
        //call create api
        let url = "/dashboard/products/create";
        if (!isEmpty(productForm._id)){
            url = "/dashboard/products/update";
        }
        jsonReq().post(url, {org_id: selOrg._id, product_details: productForm}).then(res => {
            console.log("Product saved successfully: ", res.data.data.product);
            const uProduct = res.data.data.product;
            navigate(`/products/view/${uProduct._id}`, {state: {product: uProduct, sel_org: selOrg}})
        }).catch(er => {
            setShowAlert(true);
            setSubmitError(er.message);
        })
    };

    const handleProductList = (ev) => {
        ev.preventDefault();
        const data = { sel_org: selOrg };
        navigate('/products/list', { state: data });
    };

    return (
        <Container>
            <Row>
                <Col xs={12} className="page-head">
                    <div className="page-head">
                        <span className="lead">Organisation - {selOrg.name} ({selOrg._id})</span>
                        <Nav className="ml-auto justify-content-end">
                            <Nav.Link href="/products/list" onClick={handleProductList}><FontAwesomeIcon
                                icon={faList}/>&nbsp;&nbsp;List</Nav.Link>
                        </Nav>
                    </div>
                </Col>
                <Col xs={12}>
                    {showAlert && (
                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                            There was an error while saving the Product <br/>
                            {submitError}
                        </Alert>
                    )}
                </Col>
                <Col xs={12} className="border border-1 p-3">
                    <div className="clearfix">
                        <div className="col-12 lead fs-4 fw-bold float-start">
                            <span >Add/Edit Product</span>
                        </div>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Control type="hidden" placeholder="" name="_id" value={productForm._id}/>
                        <Form.Group className="mb-3" controlId="product_name">
                            <Form.Label className="lead">Loan Product Name</Form.Label>
                            <Form.Control type="text" placeholder="" name="name" value={productForm.name}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <fieldset>
                            <span className="lead">
                                Rate of Interest
                            </span>
                            <Form.Group className="mb-3" controlId="roi.min">
                                <Form.Label className="lead">Minimum</Form.Label>
                                <Form.Control type="text" placeholder="" name="roi.min"
                                              value={productForm.roi.min} onChange={handleChange}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="roi.max">
                                <Form.Label className="lead">Maximum</Form.Label>
                                <Form.Control type="text" placeholder="" name="roi.max"
                                              value={productForm.roi.max} onChange={handleChange}/>
                            </Form.Group>
                        </fieldset>
                        <fieldset>
                            <span className="lead">
                                Loan Tenure in Years
                            </span>
                            <Form.Group className="mb-3" controlId="tenure.min">
                                <Form.Label className="lead">Minimum Loan Tenure</Form.Label>
                                <Form.Control type="text" placeholder="" name="tenure.min"
                                              value={productForm.tenure.min} onChange={handleChange}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="tenure.max">
                                <Form.Label className="lead">Maximum Loan Tenure</Form.Label>
                                <Form.Control type="text" placeholder="" name="tenure.max"
                                              value={productForm.tenure.max} onChange={handleChange}/>
                            </Form.Group>
                        </fieldset>
                        <fieldset>
                            <span className="lead">
                                Loan Amount
                            </span>
                            <Form.Group className="mb-3" controlId="amount.min">
                                <Form.Label className="lead">Minimum Loan Amount</Form.Label>
                                <Form.Control type="text" placeholder="" name="amount.min"
                                              value={productForm.amount.min} onChange={handleChange}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="amount.max">
                                <Form.Label className="lead">Maximum Loan Amount</Form.Label>
                                <Form.Control type="text" placeholder="" name="amount.max"
                                              value={productForm.amount.max} onChange={handleChange}/>
                            </Form.Group>
                        </fieldset>
                        <Form.Group className="mb-3" controlId="tnc">
                            <Form.Label className="lead">Terms and Conditions</Form.Label>
                            <Form.Control as="textarea" rows={5} placeholder="" name="tnc" value={productForm.tnc}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="btn-lg login-btn-nw">
                            Save Product
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default ProductEdit;
