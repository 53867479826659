// src/pages/branches/BranchView.js

import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Image, Nav} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useData} from "../../context/DataContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faChartSimple} from "@fortawesome/free-solid-svg-icons";
import {jsonReq} from "../../utils/HttpUtil";
import Spinner from "react-bootstrap/Spinner";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import holder from '../../images/holder.png'
import {getTitle} from '../../utils/TextUtil'

function ApplicationView() {
    const location = useLocation();
    const { orgRole } = useData();
    const navigate = useNavigate();
    const {application_id} = useParams()

    const [loading, setLoading] = useState(true);
    const [application, setApplication] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchApplication = async () => {
            try {
                const res = await jsonReq().get("/dashboard/applications/get?application_id="+application_id);
                setApplication(res.data.data.application);
            }catch (e) {
                console.log("Error fetching organisation list: ", e);
                setError(e);
            }finally {
                setLoading(false);
            }
        }
        fetchApplication()
    }, [])

    const handleApplicationList = (ev) => {
        ev.preventDefault();
        navigate('/applications/list');
    };

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    if (error) {
        return (<p>Error: {error.message}</p>);
    }

    const renderBorrowerDetails = (borrower_details) => {
        return (
            <Row>
                <Col xs={4}>
                    <Image src={borrower_details.borrower_photo?.url} thumbnail/>
                </Col>
                <Col xs={8}>
                    <Table striped bordered hover>
                        <tbody>
                        <tr>
                            <td><strong>Name</strong></td>
                            <td>{borrower_details.name?.value}</td>
                        </tr>
                        <tr>
                            <td><strong>DOB</strong></td>
                            <td>{borrower_details.dob?.value}</td>
                        </tr>
                        <tr>
                            <td><strong>Email</strong></td>
                            <td>{borrower_details.email?.value}</td>
                        </tr>
                        <tr>
                            <td><strong>Phone Number</strong></td>
                            <td>{borrower_details.mobile?.value}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        )
    }

    const renderLoanDetails = (loan_details) => {
        return (
            <Row>
                <Col xs={12}>
                    <Table striped bordered hover>
                        <tbody>
                        <tr>
                            <td><strong>Constitution</strong></td>
                            <td>{loan_details.constitution?.value}</td>
                        </tr>
                        <tr>
                            <td><strong>Industry</strong></td>
                            <td>{loan_details.industry?.value}</td>
                        </tr>
                        <tr>
                            <td><strong>Loan Quantum</strong></td>
                            <td>{loan_details.quantum?.value}</td>
                        </tr>
                        <tr>
                            <td><strong>Business Model</strong></td>
                            <td>{loan_details.business_model?.value}</td>
                        </tr>
                        <tr>
                            <td><strong>Tenure</strong></td>
                            <td>{loan_details.tenure?.value} Years</td>
                        </tr>
                        <tr>
                            <td><strong>Loan Purpose</strong></td>
                            <td>{loan_details.purpose?.value}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        )
    }

    const renderImages = (image_data) => {
        return (
            <Row>
                {Object.keys(image_data).map(k => {
                    return (
                        <Col xs={4} key={k}>
                            <span>{getTitle(k)}</span>
                            <Image key={`img-${k}`} src={image_data[k].url ? image_data[k].url : holder} thumbnail/>
                        </Col>
                    )
                })}
            </Row>
        )
    }

    const renderCoBorrowers = (co_borrowers) => {
        return co_borrowers.co_borrower?.value.map((cob) => {
            return (
                <Row className="cob-row">
                    <Col xs={4}>
                        <Image src={cob.value.photo?.url ? cob.value.photo?.url : holder} thumbnail/>
                    </Col>
                    <Col xs={8}>
                        <Table striped bordered hover>
                            <tbody>
                            <tr>
                                <td><strong>Name</strong></td>
                                <td>{cob.value.name?.value}</td>
                            </tr>
                            <tr>
                                <td><strong>DOB</strong></td>
                                <td>{cob.value.dob?.value}</td>
                            </tr>
                            <tr>
                                <td><strong>Gender</strong></td>
                                <td>{cob.value.gender?.value}</td>
                            </tr>
                            <tr>
                                <td><strong>Relation</strong></td>
                                <td>{cob.value.relation?.value}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )
        })
    }

    const renderBusinessPD = (business_pd) => {
        return (
            <Row>
                <Col xs={12}>
                    {business_pd.business_information?.type === 'file'
                        ? <AudioPlayer autoPlay={false} src={business_pd.business_information?.url}/>
                        : business_pd.business_information?.value.map(aud => (<AudioPlayer autoPlay={false} src={aud.url}/>))
                    }
                </Col>
            </Row>
        )
    }

    const renderDefault = () => {
        return (<Row>
            <Col xs={12}>
                <p>To Implement</p>
            </Col>
        </Row>)
    }

    const renderSection = (section) => {
        switch (section.name) {
            case 'borrower_details': return renderBorrowerDetails(section.data);
            case 'loan_details': return renderLoanDetails(section.data);
            case 'business_photos': return renderImages(section.data);
            case 'family_photos': return renderImages(section.data);
            case 'collateral_photos': return renderImages(section.data);
            case 'co_borrower_details': return renderCoBorrowers(section.data);
            case 'business_pd': return renderBusinessPD(section.data);
            default: return renderDefault()
        }
    }

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="page-head">
                        <span
                            className="lead">{application && application.organisation.name} ({application && application.organisation._id}) (Able ID - {application_id})</span>
                        <Nav className="ml-auto justify-content-end">
                            <Nav.Link href="/applications/list" onClick={handleApplicationList}><FontAwesomeIcon
                                icon={faList}/>&nbsp;&nbsp;List</Nav.Link>
                            {application && ['verification_pending', 'cam_review'].includes(application.status) && (
                                <Nav.Link href={`/cams/edit/${application_id}`}><FontAwesomeIcon
                                    icon={faChartSimple}/>&nbsp;&nbsp;Edit CAM</Nav.Link>
                            )}
                        </Nav>
                    </div>
                </Col>
                <Col xs={12}>
                    {
                        application && (
                            <Tabs defaultActiveKey="borrower_details" id={application._id} className="mb-3">
                                {application.sections.map(section => (
                                    <Tab key={section.name} title={getTitle(section.name)} eventKey={section.name}>{renderSection(section)}</Tab>
                                ))}
                            </Tabs>
                        )
                    }
                </Col>
            </Row>
        </Container>
    );
}

export default ApplicationView;
