// src/components/DefaultContent.js

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function DefaultContent(data) {
    const roles = data.data.roles;
    const org = data.data.organisation;
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <Card style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Title>Some Details</Card.Title>
                            <Card.Text>
                                <span className="lead display-6">{org.name}</span><br/>
                                <span className="lead">{org.type}</span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default DefaultContent;
