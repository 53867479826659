// src/pages/branches/BranchView.js

import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Nav} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useData} from "../../context/DataContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faEdit} from "@fortawesome/free-solid-svg-icons";
import {jsonReq} from "../../utils/HttpUtil";
import Spinner from "react-bootstrap/Spinner";

function BranchView() {
    const location = useLocation();
    const { orgRole } = useData();
    const navigate = useNavigate();
    const {branch_id} = useParams()
    const [selOrg] = useState(() => {
        if (location.state) {
            return location.state.sel_org
        }
        return orgRole.organisation;
    })
    const [loading, setLoading] = useState(true);
    const [branch, setBranch] = useState(null);
    const [isBranch, setIsBranch] = useState(() => !!branch);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBranch = async () => {
            try {
                const res = await jsonReq().get("/dashboard/branches/view?id="+branch_id);
                setBranch(res.data.data.branch);
                setIsBranch(true)
            }catch (e) {
                console.log("Error fetching organisation list: ", e);
                setError(e);
            }finally {
                setLoading(false);
            }
        }
        if (!isBranch) {
            fetchBranch()
        } else {
            setLoading(false);
        }
    }, [isBranch])

    const handleBranchList = (ev) => {
        ev.preventDefault();
        const data = { sel_org: selOrg };
        navigate('/branches/list', { state: data });
    };

    const handleEdit = (ev) => {
        ev.preventDefault();
        const data = { sel_org: selOrg, branch: branch };
        navigate(`/branches/edit/${branch_id}`, { state: data });
    };

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    if (error) {
        return (<p>Error: {error.message}</p>);
    }

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="page-head">
                        <span className="lead">Organisation - {selOrg.name} ({selOrg._id})</span>
                        <Nav className="ml-auto justify-content-end">
                            <Nav.Link href="/branches/list" onClick={handleBranchList}><FontAwesomeIcon
                                icon={faList}/>&nbsp;&nbsp;List</Nav.Link>
                            <Nav.Link href={`/branches/edit/${branch_id}`} onClick={handleEdit}><FontAwesomeIcon
                                icon={faEdit}/>&nbsp;&nbsp;Edit</Nav.Link>
                        </Nav>
                    </div>
                </Col>
                <Col xs={12}>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th/>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><strong>Name</strong></td>
                            <td>{branch.name}</td>
                        </tr>
                        <tr>
                            <td><strong>Branch ID</strong></td>
                            <td>{branch.branch_id}</td>
                        </tr>
                        <tr>
                            <td><strong>City</strong></td>
                            <td>{branch.city}</td>
                        </tr>
                        <tr>
                            <td><strong>State</strong></td>
                            <td>{branch.state}</td>
                        </tr>
                        <tr>
                            <td><strong>Address</strong></td>
                            <td>{branch.address}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default BranchView;
