import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    CircularProgress
} from '@mui/material';
import { jsonReq } from "../../utils/HttpUtil";
import { useNavigate, useLocation } from 'react-router-dom';
import { useData } from "../../context/DataContext";

function FormsList() {
    const location = useLocation();
    const { orgRole } = useData();
    const [organisationForms, setOrganisationForms] = useState([]);
    const [allForms, setAllForms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [orgId, setOrgId] = useState(() => {
        if (location.state) {
            return location.state.org_id;
        } else {
            return orgRole.organisation._id;
        }
    });

    useEffect(() => {
        const fetchFormsData = async () => {
            try {
                const res = await jsonReq().get(`/dashboard/forms/data?org_id=${orgId}`);
                setOrganisationForms(res.data.data.organisationForms);
                setAllForms(res.data.data.allForms);
            } catch (e) {
                console.error("Error fetching forms data: ", e);
                setError(e);
            } finally {
                setLoading(false);
            }
        }
        fetchFormsData();
    }, [orgId]);

    const handleRemove = async (form_id) => {
        try {
            await jsonReq().delete(`/dashboard/forms/${orgId}/${form_id}`);
            setOrganisationForms(organisationForms.filter(f => f._id !== form_id));
        } catch (e) {
            console.error("Error removing form: ", e);
        }
    };

    const handleAdd = async (form_id) => {
        try {
            await jsonReq().post(`/dashboard/forms/${orgId}/${form_id}`);
            setOrganisationForms([...organisationForms, allForms.find(f => f._id === form_id)]);
        } catch (e) {
            console.error("Error adding form: ", e);
        }
    };

    if (loading) {
        return (
            <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (error) {
        return <Typography color="error">Error: {error.message}</Typography>;
    }

    return (
        <Container>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        style={{
                            color: '#3f51b5',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginBottom: '20px'
                        }}
                    >
                        Organisation forms
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {organisationForms.map((form, index) => (
                                    <TableRow key={form._id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{form.name}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleRemove(form._id)}
                                            >
                                                Remove
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        style={{
                            color: '#3f51b5',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginBottom: '20px'
                        }}
                    >
                        All Forms
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allForms.map((form, index) => (
                                    <TableRow key={form._id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{form.name}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleAdd(form._id)}
                                            >
                                                Add
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Container>
    );
}

export default FormsList;
