import React, {useEffect, useState} from 'react';
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import "../../css/dashboard.css"

function DashboardNav({ data }) {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const roles = data.roles;
    const org = data.organisation;

    let orgListItem = "";
    const handleOrgList = (event) => {
        event.preventDefault();
        navigate('/organisations/list')
    }

    if (user.admin) {
        orgListItem = <NavDropdown.Item href="#" className="bg-black" onClick={handleOrgList}>Organisation List</NavDropdown.Item>
    }

    const handleLogout = (event) => {
        event.preventDefault();
        logout();
        navigate("/login");
    };

    return (
        <Navbar expand="lg" className="top-nav">
            <Container>
                <Navbar.Brand href="#">AbleCredit</Navbar.Brand>
                <Nav className="ml-auto">
                    <Nav.Link>{user.name}</Nav.Link>
                    <NavDropdown title="Settings" className="bg-black">
                        <NavDropdown.Item href="#" className="bg-black" onClick={handleLogout}>Logout</NavDropdown.Item>
                        {orgListItem}
                    </NavDropdown>
                </Nav>
            </Container>
        </Navbar>
    );
}

export default DashboardNav;
