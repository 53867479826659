import Accordion from "react-bootstrap/Accordion";
import {getTitle} from "../../utils/TextUtil";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

function CollateralImages({dataKey, collateral_images}) {
    return (
        <Accordion.Item eventKey={dataKey} key={dataKey}>
            <Accordion.Header>{getTitle(dataKey)}</Accordion.Header>
            <Accordion.Body>
                <Row>
                    <Col xs={12}>
                        <table className="table table-striped">
                            <tbody>
                            {collateral_images && Object.entries(collateral_images).map((entry, idx) => {
                                return (
                                    <tr key={`col_img_${idx}`}>
                                        <td>{entry[0]}</td>
                                        <td>{entry[1]}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    )
}
export default CollateralImages;