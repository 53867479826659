// src/components/BranchEdit.js

import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Nav} from "react-bootstrap";
import Multiselect from 'multiselect-react-dropdown';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {getErrorMessage, isEmpty} from "../../utils/TextUtil";
import {jsonReq} from "../../utils/HttpUtil";
import {useData} from "../../context/DataContext";
import useFetch from "../../utils/UseFetch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";
import Ajv from 'ajv'
import Spinner from "react-bootstrap/Spinner";
import '../../css/dashboard.css'

function UserEdit() {
    const location = useLocation();
    const navigate = useNavigate();
    const { orgRole } = useData();
    const {user_id} = useParams()
    const [selOrg] = useState(() => {
        if (location.state) {
            return location.state.sel_org
        } else {
            return orgRole.organisation;
        }
    })
    const [user, setUser] = useState(() => {
        if (location.state) {
            return location.state.user;
        } else {
            return null;
        }
    });
    const [fetchUser, setFetchUser] = useState(() => {
        if (!user) {
            if (user_id) {
                return true;
            }
        }
        return false;
    });
    const [showAlert, setShowAlert] = useState(false);
    const [submitError, setSubmitError] = useState("");
    const [roles, setRoles] = useState(null);
    const [branches, setBranches] = useState(null);

    const [hasRoles, setHasRoles] = useState(() => {
        return (roles && roles.length > 0);
    });

    const [hasBranches, setHasBranches] = useState(() => {
        return (branches && branches.length > 0);
    });

    const [userForm, setUserForm] = useState(() => {
        if (user) {
            return {
                _id: user._id,
                name: user.name,
                email: user.email,
                mobile: user.mobile,
                org_admin: user.org_admin,
                roles: user.roles.map(r => r._id),
                branch_id: user.branch_id,
                login_types: user.login_types,
            }
        }
        return {
            name: "",
            email: "",
            mobile: "",
            org_admin: false,
            roles: [],
            branch_id: "",
            branches: [],
            login_types: ["mobile"]
        };
    });

    // const [isOrgAdmin, setIsOrgAdmin] = useState(userForm.org_admin);

    const {data, loading, error} = useFetch(`/dashboard/users/view?id=${user_id}`, fetchUser);

    const {data: roleData, loading: roleLoading, error: roleError} =
        useFetch(`/dashboard/roles/list?org_id=${selOrg._id}`, !hasRoles);

    const {data: branchData, loading: branchLoading, error: branchError} =
        useFetch(`/dashboard/branches/list?org_id=${selOrg._id}`, !hasBranches);

    useEffect(() => {
        if (data) {
            setUser(data.user);
            setFetchUser(false)
        }
    }, [data]);

    useEffect(() => {
        if (roleData) {
            setRoles(roleData.roles);
            setHasRoles(true)
        }
    }, [roleData]);

    useEffect(() => {
        if (branchData) {
            setBranches(branchData.branches);
            setHasBranches(true)
            setUserForm({
                ...userForm,
                branch_id: branchData.branches[0]._id,
            })
        }
    }, [branchData]);

    if (loading || roleLoading || branchLoading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    if (error || roleError || branchError) {
        let e = error || roleError || branchError
        return <p>Error: {e.message}</p>;
    }

    const userSchema = {
        type: 'object',
        properties: {
            _id: {type: 'string'},
            name: {type: 'string'},
            email: {type: 'string'},
            mobile: {type: 'string'},
            org_admin: {type: 'boolean'},
            roles: {type: 'array', items: {type: 'string'}},
            branch_id: {type: 'string'},
            branches: {type: 'array', items: {type: 'string'}},
        },
        required: ['name', 'branch_id', 'email', 'mobile']
    }
    const ajv = new Ajv();
    const validate = ajv.compile(userSchema)

    const handleChange = (ev) => {
        const {name, value} = ev.target;
        if (name.includes(".")) {
            const [section, field] = name.split('.');
            setUserForm(prevState => ({
                ...prevState,
                [section]: {
                    ...prevState[section],
                    [field]: value
                }
            }));
        } else {
            setUserForm({
                ...userForm,
                [name]: value
            });
        }
        setShowAlert(false);
    };

    const handleOrgAdminChange = (ev) => {
        // ev.preventDefault();
        const {name, checked} = ev.target;
        setUserForm({
            ...userForm,
            [name]: checked
        })
        setShowAlert(false);
    }

    const addRoleToUser = (selectedList, selectedItem) => {
        setUserForm({
            ...userForm,
            roles: selectedList.map(sl => sl.id)
        })
    }

    const removeRoleFromUser = (selectedList, removedItem) => {
        setUserForm({
            ...userForm,
            roles: selectedList.map(sl => sl.id)
        })
    }

    const addBranchToUser = (selectedList, selectedItem) => {
        setUserForm({
            ...userForm,
            branches: selectedList.map(sl => sl.id)
        })
    }

    const removeLoginType = (selectedList, removedItem) => {
        setUserForm({
            ...userForm,
            login_types: selectedList.map(sl => sl.id)
        })
    }

    const addLoginType = (selectedList, selectedItem) => {
        setUserForm({
            ...userForm,
            login_types: selectedList.map(sl => sl.id)
        })
    }

    const removeBranchFromUser = (selectedList, removedItem) => {
        setUserForm({
            ...userForm,
            branches: selectedList.map(sl => sl.id)
        })
    }

    const validateForm = () => {
        let show = false;
        let err = "";
        // convert string to number
        const valid = validate(userForm);
        if (!valid) {
            show = true;
            err = validate.errors[0].instancePath+" "+validate.errors[0].message
        }
        return [show, err];
    };

    const handleSubmit = (ev) => {
        ev.preventDefault();
        const [show, err] = validateForm();
        if (show) {
            setSubmitError(err);
            setShowAlert(true);
            return
        }
        //call create api
        let url = "/dashboard/users/create";
        if (!isEmpty(userForm._id)){
            url = "/dashboard/users/update";
        }
        jsonReq().post(url, {org_id: selOrg._id, user_details: userForm}).then(res => {
            console.log("User saved successfully: ", res.data.data.user);
            const uUser = res.data.data.user;
            navigate(`/users/view/${uUser._id}`, {state: {sel_org: selOrg}})
        }).catch(er => {
            setSubmitError(getErrorMessage(er));
            setShowAlert(true);
            console.log(submitError);
        })
    };
    const loginTypes = [
        {
            id: "mobile",
            name: "Mobile",
        },
        {
            id: "sso",
            name: "SSO"
        }
    ];
    let selectedLoginTypes = [];
    if (user && user.login_types && user.login_types.length > 0) {
        selectedLoginTypes = loginTypes.filter(lt => user.login_types.includes(lt.id))
    }
    let roleOptions = []
    let selectedRoles = [];
    let branchesOptions = []
    let selectedBranches = [];
    if (roles && roles.length > 0) {
        roleOptions = roles.map(role => ({name: role.display_name, id: role._id}));
        if (user && user.roles && user.roles.length > 0) {
            selectedRoles = roles.filter(rl => user.roles.map(r => r._id).includes(rl._id)).map(role => ({name: role.display_name, id: role._id}));
        }
    }
    if (branches && branches.length > 0) {
        branchesOptions = branches.map(branch => ({name: branch.name, id: branch._id}));
        if(user && user.branches && user.branches.length > 0) {
            selectedBranches = branches.filter(branch => user.branches.includes(branch._id)).map(branch => ({name: branch.name, id: branch._id}));
        }
    }

    let branchOptions = [];
    if (branches && branches.length > 0) {
        branches.forEach(br => {
            branchOptions.push(
                <option value={br._id} key={br._id}>{br.name}</option>
            )
        })
    }

    const backToUserList = (ev) => {
        ev.preventDefault();
        const data = { sel_org: selOrg };
        navigate('/users/list', { state: data });
    };

    return (
        <Container>
            <Row>
                <Col xs={12} className="page-head">
                    <div className="page-head">
                        <span className="lead">Organisation - {selOrg.name} ({selOrg._id})</span>
                        <Nav className="ml-auto justify-content-end">
                            <Nav.Link href="/users/list" onClick={backToUserList}><FontAwesomeIcon
                                icon={faList}/>&nbsp;&nbsp;List</Nav.Link>
                        </Nav>
                    </div>
                </Col>
                <Col xs={12}>
                    {showAlert && (
                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                            There was an error while saving the User <br/>
                            {submitError}
                        </Alert>
                    )}
                </Col>
                <Col xs={12} className="border border-1 p-3">
                    <div className="clearfix">
                        <div className="col-12 lead fs-4 fw-bold float-start">
                            <span >Add/Edit User</span>
                        </div>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Control type="hidden" placeholder="" name="_id" value={userForm._id}/>
                        <Form.Group className="mb-3" controlId="user_name">
                            <Form.Label className="lead">Name</Form.Label>
                            <Form.Control type="text" placeholder="" name="name" value={userForm.name}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="user_email">
                            <Form.Label className="lead">Email</Form.Label>
                            <Form.Control type="text" placeholder="" name="email" value={userForm.email}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="user_mobile">
                            <Form.Label className="lead">Phone Number</Form.Label>
                            <Form.Control type="text" placeholder="" name="mobile" value={userForm.mobile}
                                          onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="login_types">
                            <Form.Label className="lead">Select branches that can be accessed by the
                                user</Form.Label>
                            <Multiselect className="white-bg" options={loginTypes}
                                         selectedValues={selectedLoginTypes} displayValue="name"
                                         placeholder="Select Login Types" onSelect={addLoginType}
                                         onRemove={removeLoginType}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="user_org_admin">
                            <Form.Label className="lead">Is Organisation Admin</Form.Label>
                            <Form.Check className="checkbox-custom" type="checkbox" onChange={handleOrgAdminChange}
                                        name="org_admin" checked={userForm.org_admin}/>
                        </Form.Group>
                        {userForm.org_admin && (
                            <fieldset>
                                <Form.Group className="mb-3" controlId="user_branches">
                                    <Form.Label className="lead">Select branches that can be accessed by the
                                        user</Form.Label>
                                    <Multiselect className="white-bg" options={branchesOptions}
                                                 selectedValues={selectedBranches} displayValue="name"
                                                 placeholder="Select branches" onSelect={addBranchToUser}
                                                 onRemove={removeBranchFromUser}/>
                                </Form.Group>
                            </fieldset>
                        )}
                        <Form.Group className="mb-3" controlId="user_branch_id">
                            <Form.Label className="lead">Branch</Form.Label>
                            <Form.Select name="branch_id" onChange={handleChange} value={userForm.branch_id}>
                                {branchOptions}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="user_roles">
                            <Form.Label className="lead">User Roles</Form.Label>
                            <Multiselect className="white-bg" options={roleOptions} selectedValues={selectedRoles}
                                         displayValue="name" placeholder="Select roles" onSelect={addRoleToUser}
                                         onRemove={removeRoleFromUser}/>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="btn-lg login-btn-nw">
                            Save User
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default UserEdit;
